/*-----------------------------------------------------------------------------
    $ Card
-----------------------------------------------------------------------------*/

@use '../util/' as *;

.cards {
    margin-left: rem(-10);

    @include breakpoint-dn(md) {
        margin-left: unset;
    }
}

.card {
    border-radius: 10px;
    background-color: var(--color-bg-card);
    margin: 0 rem(10);
    text-align: center;
    flex: 1;


    @include breakpoint-dn(md) {
        // margin: rem(10) 0;
    }

    &__inner {
        padding: rem(45) rem(25) rem(40);
    }

    &__img {
        margin: 0 auto;
        width: rem(60);
    }
}


// To allow equal height cards within Slick Slider
.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}

.slick-dots {
    bottom: -45px;
    
    li {
        & button:before {
            color: var(--color-grey) !important;
        }
    
        &.slick-active {
            & button:before {
                color: var(--color-primary-blue) !important;
            }
        }
    }
}

.slick-prev.slick-arrow {
    left: rem(-15);
    z-index: 1;
}

.slick-next.slick-arrow {
    right: rem(-15);
    z-index: 1;
}