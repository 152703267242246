/*-----------------------------------------------------------------------------
    $ Fonts -- Horatio D
-----------------------------------------------------------------------------*/

@font-face {
    font-family: "Horatio D";
    src: url("assets/fonts/horatio-d/HoratioD-Ligh.eot");
    src: url("assets/fonts/horatio-d/HoratioD-Ligh.eot?#iefix") format("embedded-opentype"),
        url("assets/fonts/horatio-d/HoratioD-Ligh.woff2") format("woff2"),
        url("assets/fonts/horatio-d/HoratioD-Ligh.woff") format("woff"),
        url("assets/fonts/horatio-d/HoratioD-Ligh.ttf") format("truetype"),
        url("assets/fonts/horatio-d/HoratioD-Ligh.svg#HoratioD-Ligh") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Horatio D";
    src: url("assets/fonts/horatio-d/HoratioD-Medi.eot");
    src: url("assets/fonts/horatio-d/HoratioD-Medi.eot?#iefix") format("embedded-opentype"),
        url("assets/fonts/horatio-d/HoratioD-Medi.woff2") format("woff2"),
        url("assets/fonts/horatio-d/HoratioD-Medi.woff") format("woff"),
        url("assets/fonts/horatio-d/HoratioD-Medi.ttf") format("truetype"),
        url("assets/fonts/horatio-d/HoratioD-Medi.svg#HoratioD-Medi") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Horatio D";
    src: url("assets/fonts/horatio-d/HoratioD-Bold.eot");
    src: url("assets/fonts/horatio-d/HoratioD-Bold.eot?#iefix") format("embedded-opentype"),
        url("assets/fonts/horatio-d/HoratioD-Bold.woff2") format("woff2"),
        url("assets/fonts/horatio-d/HoratioD-Bold.woff") format("woff"),
        url("assets/fonts/horatio-d/HoratioD-Bold.ttf") format("truetype"),
        url("assets/fonts/horatio-d/HoratioD-Bold.svg#HoratioD-Bold") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
