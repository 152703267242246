/*-----------------------------------------------------------------------------
    $ Section
-----------------------------------------------------------------------------*/
@use "../util/" as *;

.section {
    min-height: 100vh;
    padding: rem(100) 0;

    &--mh-unset {
        min-height: unset;
    }

    &--linktree {
        max-width: rem(480);
        margin: 0 auto;

        @include breakpoint-dn(xl) {
            padding-left: rem(25);
            padding-right: rem(25);
        }
    
        @include breakpoint-dn(md) {
            padding-left: rem(25);
            padding-right: rem(25);
        }
    }

    &__heading {
        font-family: var(--font-horatio);
        font-size: rem(32);
        @include font-weight(500);
        color: var(--color-heading-text);
        margin: 0;

        @include breakpoint-dn(md) {
            font-size: rem(28);
        }

        @include breakpoint-dn(sm) {
            font-size: rem(24);
        }

        &-label {
            font-family: var(--font-roboto);
            font-size: rem(16);
            color: var(--color-subheading-text);
            @include font-weight(500);
        }
    }
}
