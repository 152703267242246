/*-----------------------------------------------------------------------------
    $ Layout
-----------------------------------------------------------------------------*/

@use "../util" as *;

.container {
    padding: 0;
    max-width: rem(1000);
    margin: 0 auto rem(46);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint-dn(xl) {
        padding: 0 rem(25);
        margin-left: rem(25);
        margin-right: rem(25);
    }

    @include breakpoint-dn(md) {
        padding: 0 rem(25);
    }

    &--hero {
        // padding-top: rem(197);
    }
}