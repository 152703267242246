@charset "UTF-8";
/*-----------------------------------------------------------------------------
    $ Fonts -- Horatio D
-----------------------------------------------------------------------------*/
@font-face {
  font-family: "Horatio D";
  src: url("assets/fonts/horatio-d/HoratioD-Ligh.eot");
  src: url("assets/fonts/horatio-d/HoratioD-Ligh.eot?#iefix") format("embedded-opentype"), url("assets/fonts/horatio-d/HoratioD-Ligh.woff2") format("woff2"), url("assets/fonts/horatio-d/HoratioD-Ligh.woff") format("woff"), url("assets/fonts/horatio-d/HoratioD-Ligh.ttf") format("truetype"), url("assets/fonts/horatio-d/HoratioD-Ligh.svg#HoratioD-Ligh") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Horatio D";
  src: url("assets/fonts/horatio-d/HoratioD-Medi.eot");
  src: url("assets/fonts/horatio-d/HoratioD-Medi.eot?#iefix") format("embedded-opentype"), url("assets/fonts/horatio-d/HoratioD-Medi.woff2") format("woff2"), url("assets/fonts/horatio-d/HoratioD-Medi.woff") format("woff"), url("assets/fonts/horatio-d/HoratioD-Medi.ttf") format("truetype"), url("assets/fonts/horatio-d/HoratioD-Medi.svg#HoratioD-Medi") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Horatio D";
  src: url("assets/fonts/horatio-d/HoratioD-Bold.eot");
  src: url("assets/fonts/horatio-d/HoratioD-Bold.eot?#iefix") format("embedded-opentype"), url("assets/fonts/horatio-d/HoratioD-Bold.woff2") format("woff2"), url("assets/fonts/horatio-d/HoratioD-Bold.woff") format("woff"), url("assets/fonts/horatio-d/HoratioD-Bold.ttf") format("truetype"), url("assets/fonts/horatio-d/HoratioD-Bold.svg#HoratioD-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
/*-----------------------------------------------------------------------------
    $ Fonts -- Roboto
-----------------------------------------------------------------------------*/
/** Roboto Thin **/
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-ThinItalic.eot");
  src: url("assets/fonts/roboto/Roboto-ThinItalic.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-ThinItalic.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-ThinItalic.woff") format("woff"), url("assets/fonts/roboto/Roboto-ThinItalic.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-ThinItalic.svg#Roboto-ThinItalic") format("svg");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Thin.eot");
  src: url("assets/fonts/roboto/Roboto-Thin.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-Thin.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-Thin.woff") format("woff"), url("assets/fonts/roboto/Roboto-Thin.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-Thin.svg#Roboto-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
/** Roboto Light **/
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Light.eot");
  src: url("assets/fonts/roboto/Roboto-Light.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-Light.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-Light.woff") format("woff"), url("assets/fonts/roboto/Roboto-Light.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-Light.svg#Roboto-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-LightItalic.eot");
  src: url("assets/fonts/roboto/Roboto-LightItalic.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-LightItalic.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-LightItalic.woff") format("woff"), url("assets/fonts/roboto/Roboto-LightItalic.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-LightItalic.svg#Roboto-LightItalic") format("svg");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
/** Roboto **/
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Regular.eot");
  src: url("assets/fonts/roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-Regular.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-Regular.woff") format("woff"), url("assets/fonts/roboto/Roboto-Regular.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-Regular.svg#Roboto-Regular") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Italic.eot");
  src: url("assets/fonts/roboto/Roboto-Italic.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-Italic.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-Italic.woff") format("woff"), url("assets/fonts/roboto/Roboto-Italic.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-Italic.svg#Roboto-Italic") format("svg");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Medium.eot");
  src: url("assets/fonts/roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-Medium.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-Medium.woff") format("woff"), url("assets/fonts/roboto/Roboto-Medium.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-Medium.svg#Roboto-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-MediumItalic.eot");
  src: url("assets/fonts/roboto/Roboto-MediumItalic.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-MediumItalic.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-MediumItalic.woff") format("woff"), url("assets/fonts/roboto/Roboto-MediumItalic.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-MediumItalic.svg#Roboto-MediumItalic") format("svg");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Bold.eot");
  src: url("assets/fonts/roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-Bold.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-Bold.woff") format("woff"), url("assets/fonts/roboto/Roboto-Bold.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-Bold.svg#Roboto-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-BoldItalic.eot");
  src: url("assets/fonts/roboto/Roboto-BoldItalic.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-BoldItalic.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-BoldItalic.woff") format("woff"), url("assets/fonts/roboto/Roboto-BoldItalic.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-BoldItalic.svg#Roboto-BoldItalic") format("svg");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
/** Roboto Black **/
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-BlackItalic.eot");
  src: url("assets/fonts/roboto/Roboto-BlackItalic.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-BlackItalic.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-BlackItalic.woff") format("woff"), url("assets/fonts/roboto/Roboto-BlackItalic.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-BlackItalic.svg#Roboto-BlackItalic") format("svg");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto";
  src: url("assets/fonts/roboto/Roboto-Black.eot");
  src: url("assets/fonts/roboto/Roboto-Black.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-Black.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-Black.woff") format("woff"), url("assets/fonts/roboto/Roboto-Black.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-Black.svg#Roboto-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
/** Roboto Condensed **/
@font-face {
  font-family: "Roboto Condensed";
  src: url("assets/fonts/roboto/Roboto-Condensed.eot");
  src: url("assets/fonts/roboto/Roboto-Condensed.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-Condensed.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-Condensed.woff") format("woff"), url("assets/fonts/roboto/Roboto-Condensed.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-Condensed.svg#Roboto-Condensed") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("assets/fonts/roboto/Roboto-CondensedItalic.eot");
  src: url("assets/fonts/roboto/Roboto-CondensedItalic.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-CondensedItalic.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-CondensedItalic.woff") format("woff"), url("assets/fonts/roboto/Roboto-CondensedItalic.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-CondensedItalic.svg#Roboto-CondensedItalic") format("svg");
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("assets/fonts/roboto/Roboto-BoldCondensed.eot");
  src: url("assets/fonts/roboto/Roboto-BoldCondensed.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-BoldCondensed.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-BoldCondensed.woff") format("woff"), url("assets/fonts/roboto/Roboto-BoldCondensed.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-BoldCondensed.svg#Roboto-BoldCondensed") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("assets/fonts/roboto/Roboto-BoldCondensedItalic.eot");
  src: url("assets/fonts/roboto/Roboto-BoldCondensedItalic.eot?#iefix") format("embedded-opentype"), url("assets/fonts/roboto/Roboto-BoldCondensedItalic.woff2") format("woff2"), url("assets/fonts/roboto/Roboto-BoldCondensedItalic.woff") format("woff"), url("assets/fonts/roboto/Roboto-BoldCondensedItalic.ttf") format("truetype"), url("assets/fonts/roboto/Roboto-BoldCondensedItalic.svg#Roboto-BoldCondensedItalic") format("svg");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}
/*-----------------------------------------------------------------------------
    $ Fonts : Globals
-----------------------------------------------------------------------------*/
:root {
  --font-horatio: "Horatio D";
  --font-roboto: "Roboto";
}

/*-----------------------------------------------------------------------------
    $ Colors
-----------------------------------------------------------------------------*/
:root {
  --color-black: hsl(0, 0%, 0%);
  --color-bg-blue: hsla(220, 100%, 13%, 1);
  --color-bg-dark-blue: hsla(220, 100%, 8%, 1);
  --color-navy-blue: hsla(220, 100%, 21%, 1);
  --color-primary-blue: hsla(204, 100%, 50%, 1);
  --color-white: hsla(201, 100%, 97%, 1);
  --color-grey: hsla(217, 34%, 60%, 1);
  --color-bg-card: hsla(220, 86%, 19%, 1);
  --color-bg-tag: hsla(204, 100%, 23%, 1);
  --color-subheading-text: var(--color-primary-blue);
  --color-heading-text: var(--color-white);
  --color-heading-text-2: var(--color-grey);
  --color-body-text: var(--color-grey);
  --color-nav-link: var(--color-white);
  --color-bg-main: var(--color-bg-blue);
  --color-mobile-nav-menu: hsla(0, 0%, 0%, 0.5);
  --color-tab-active-text: var(--color-primary-blue);
  --color-tab-active-text-mobile: var(--color-white);
  --color-tab-active-text-bg-mobile: var(--color-primary-blue);
  --color-tab-border-mobile: hsl(214, 90%, 25%);
  --color-tab-default-text: var(--color-grey);
  --color-tab-line: var(--color-primary-blue);
  --color-tab-line-bg: hsla(220, 14%, 45%, 1);
  --color-tab-content-bg: var(--color-bg-card);
}

/*-----------------------------------------------------------------------------
    $ Boilerplate
-----------------------------------------------------------------------------*/
html {
  font-size: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-roboto), Helvetica, sans-serif;
  background-color: var(--color-bg-main);
}
body.mobile-menu-active, body.popup-active {
  overflow-y: hidden;
}
body.bg-white {
  background-color: white;
}

/*-----------------------------------------------------------------------------
    $ Breakpoints
-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------
    $ Functions
-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------
    $ Fonts : Utils
-----------------------------------------------------------------------------*/
.fw-100 {
  font-weight: 100;
}

.fw-200 {
  font-weight: 200;
}

.fw-200 {
  font-weight: 200;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-400 {
  font-weight: 400;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.fw-800 {
  font-weight: 800;
}

.fw-900 {
  font-weight: 900;
}

.fw-900 {
  font-weight: 900;
}

.fw-900 {
  font-weight: 900;
}

.fw-900 {
  font-weight: 900;
}

.fw-900 {
  font-weight: 900;
}

.fw-900 {
  font-weight: 900;
}

.fs-xl {
  font-size: 3rem;
}

.fs-lg {
  font-size: 2.25rem;
}

.fs-md {
  font-size: 1.5rem;
}

.fs-sm {
  font-size: 1.125rem;
}

.fs-xs {
  font-size: 1rem;
}

.fs-xxs {
  font-size: 0.875rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

/*-----------------------------------------------------------------------------
    $ Spacing

    References:
    https://github.com/malothnaresh/css-utilities/blob/master/common.scss
    https://medium.com/@jerrythimothy/margins-paddings-sass-mixin-16460c32507f
-----------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------
    Bootstrap utility classes:
    p-0: [padding: 0rem]
    ml-0: [margin-left: 0rem]

    pl-1 : [ padding-left: 0.25rem ]
    mr-2 : [ margin-right: 0.5rem ]
    pt-3 : [ padding-top: 0.75rem ]
    mb-4 : [ margin-bottom: 1rem ]

    pl-5 : [ padding-left: 1.25rem ]
    mr-6 : [ margin-right: 1.5rem ]
    pt-7 : [ padding-top: 1.75rem ]
    mb-8 : [ margin-bottom: 2rem ]
-----------------------------------------------------------------------------*/
.m-0 {
  margin: 0rem;
}

.p-0 {
  padding: 0rem;
}

.mt-0 {
  margin-top: 0rem !important;
}

.pt-0 {
  padding-top: 0rem !important;
}

.mr-0 {
  margin-right: 0rem !important;
}

.pr-0 {
  padding-right: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.pl-0 {
  padding-left: 0rem !important;
}

.m-5 {
  margin: 0.3125rem;
}

.p-5 {
  padding: 0.3125rem;
}

.mt-5 {
  margin-top: 0.3125rem !important;
}

.pt-5 {
  padding-top: 0.3125rem !important;
}

.mr-5 {
  margin-right: 0.3125rem !important;
}

.pr-5 {
  padding-right: 0.3125rem !important;
}

.mb-5 {
  margin-bottom: 0.3125rem !important;
}

.pb-5 {
  padding-bottom: 0.3125rem !important;
}

.ml-5 {
  margin-left: 0.3125rem !important;
}

.pl-5 {
  padding-left: 0.3125rem !important;
}

.m-10 {
  margin: 0.625rem;
}

.p-10 {
  padding: 0.625rem;
}

.mt-10 {
  margin-top: 0.625rem !important;
}

.pt-10 {
  padding-top: 0.625rem !important;
}

.mr-10 {
  margin-right: 0.625rem !important;
}

.pr-10 {
  padding-right: 0.625rem !important;
}

.mb-10 {
  margin-bottom: 0.625rem !important;
}

.pb-10 {
  padding-bottom: 0.625rem !important;
}

.ml-10 {
  margin-left: 0.625rem !important;
}

.pl-10 {
  padding-left: 0.625rem !important;
}

.m-15 {
  margin: 0.9375rem;
}

.p-15 {
  padding: 0.9375rem;
}

.mt-15 {
  margin-top: 0.9375rem !important;
}

.pt-15 {
  padding-top: 0.9375rem !important;
}

.mr-15 {
  margin-right: 0.9375rem !important;
}

.pr-15 {
  padding-right: 0.9375rem !important;
}

.mb-15 {
  margin-bottom: 0.9375rem !important;
}

.pb-15 {
  padding-bottom: 0.9375rem !important;
}

.ml-15 {
  margin-left: 0.9375rem !important;
}

.pl-15 {
  padding-left: 0.9375rem !important;
}

.m-20 {
  margin: 1.25rem;
}

.p-20 {
  padding: 1.25rem;
}

.mt-20 {
  margin-top: 1.25rem !important;
}

.pt-20 {
  padding-top: 1.25rem !important;
}

.mr-20 {
  margin-right: 1.25rem !important;
}

.pr-20 {
  padding-right: 1.25rem !important;
}

.mb-20 {
  margin-bottom: 1.25rem !important;
}

.pb-20 {
  padding-bottom: 1.25rem !important;
}

.ml-20 {
  margin-left: 1.25rem !important;
}

.pl-20 {
  padding-left: 1.25rem !important;
}

.m-25 {
  margin: 1.5625rem;
}

.p-25 {
  padding: 1.5625rem;
}

.mt-25 {
  margin-top: 1.5625rem !important;
}

.pt-25 {
  padding-top: 1.5625rem !important;
}

.mr-25 {
  margin-right: 1.5625rem !important;
}

.pr-25 {
  padding-right: 1.5625rem !important;
}

.mb-25 {
  margin-bottom: 1.5625rem !important;
}

.pb-25 {
  padding-bottom: 1.5625rem !important;
}

.ml-25 {
  margin-left: 1.5625rem !important;
}

.pl-25 {
  padding-left: 1.5625rem !important;
}

.m-30 {
  margin: 1.875rem;
}

.p-30 {
  padding: 1.875rem;
}

.mt-30 {
  margin-top: 1.875rem !important;
}

.pt-30 {
  padding-top: 1.875rem !important;
}

.mr-30 {
  margin-right: 1.875rem !important;
}

.pr-30 {
  padding-right: 1.875rem !important;
}

.mb-30 {
  margin-bottom: 1.875rem !important;
}

.pb-30 {
  padding-bottom: 1.875rem !important;
}

.ml-30 {
  margin-left: 1.875rem !important;
}

.pl-30 {
  padding-left: 1.875rem !important;
}

.m-35 {
  margin: 2.1875rem;
}

.p-35 {
  padding: 2.1875rem;
}

.mt-35 {
  margin-top: 2.1875rem !important;
}

.pt-35 {
  padding-top: 2.1875rem !important;
}

.mr-35 {
  margin-right: 2.1875rem !important;
}

.pr-35 {
  padding-right: 2.1875rem !important;
}

.mb-35 {
  margin-bottom: 2.1875rem !important;
}

.pb-35 {
  padding-bottom: 2.1875rem !important;
}

.ml-35 {
  margin-left: 2.1875rem !important;
}

.pl-35 {
  padding-left: 2.1875rem !important;
}

.m-40 {
  margin: 2.5rem;
}

.p-40 {
  padding: 2.5rem;
}

.mt-40 {
  margin-top: 2.5rem !important;
}

.pt-40 {
  padding-top: 2.5rem !important;
}

.mr-40 {
  margin-right: 2.5rem !important;
}

.pr-40 {
  padding-right: 2.5rem !important;
}

.mb-40 {
  margin-bottom: 2.5rem !important;
}

.pb-40 {
  padding-bottom: 2.5rem !important;
}

.ml-40 {
  margin-left: 2.5rem !important;
}

.pl-40 {
  padding-left: 2.5rem !important;
}

.m-45 {
  margin: 2.8125rem;
}

.p-45 {
  padding: 2.8125rem;
}

.mt-45 {
  margin-top: 2.8125rem !important;
}

.pt-45 {
  padding-top: 2.8125rem !important;
}

.mr-45 {
  margin-right: 2.8125rem !important;
}

.pr-45 {
  padding-right: 2.8125rem !important;
}

.mb-45 {
  margin-bottom: 2.8125rem !important;
}

.pb-45 {
  padding-bottom: 2.8125rem !important;
}

.ml-45 {
  margin-left: 2.8125rem !important;
}

.pl-45 {
  padding-left: 2.8125rem !important;
}

.m-50 {
  margin: 3.125rem;
}

.p-50 {
  padding: 3.125rem;
}

.mt-50 {
  margin-top: 3.125rem !important;
}

.pt-50 {
  padding-top: 3.125rem !important;
}

.mr-50 {
  margin-right: 3.125rem !important;
}

.pr-50 {
  padding-right: 3.125rem !important;
}

.mb-50 {
  margin-bottom: 3.125rem !important;
}

.pb-50 {
  padding-bottom: 3.125rem !important;
}

.ml-50 {
  margin-left: 3.125rem !important;
}

.pl-50 {
  padding-left: 3.125rem !important;
}

.m-55 {
  margin: 3.4375rem;
}

.p-55 {
  padding: 3.4375rem;
}

.mt-55 {
  margin-top: 3.4375rem !important;
}

.pt-55 {
  padding-top: 3.4375rem !important;
}

.mr-55 {
  margin-right: 3.4375rem !important;
}

.pr-55 {
  padding-right: 3.4375rem !important;
}

.mb-55 {
  margin-bottom: 3.4375rem !important;
}

.pb-55 {
  padding-bottom: 3.4375rem !important;
}

.ml-55 {
  margin-left: 3.4375rem !important;
}

.pl-55 {
  padding-left: 3.4375rem !important;
}

.m-60 {
  margin: 3.75rem;
}

.p-60 {
  padding: 3.75rem;
}

.mt-60 {
  margin-top: 3.75rem !important;
}

.pt-60 {
  padding-top: 3.75rem !important;
}

.mr-60 {
  margin-right: 3.75rem !important;
}

.pr-60 {
  padding-right: 3.75rem !important;
}

.mb-60 {
  margin-bottom: 3.75rem !important;
}

.pb-60 {
  padding-bottom: 3.75rem !important;
}

.ml-60 {
  margin-left: 3.75rem !important;
}

.pl-60 {
  padding-left: 3.75rem !important;
}

.m-65 {
  margin: 4.0625rem;
}

.p-65 {
  padding: 4.0625rem;
}

.mt-65 {
  margin-top: 4.0625rem !important;
}

.pt-65 {
  padding-top: 4.0625rem !important;
}

.mr-65 {
  margin-right: 4.0625rem !important;
}

.pr-65 {
  padding-right: 4.0625rem !important;
}

.mb-65 {
  margin-bottom: 4.0625rem !important;
}

.pb-65 {
  padding-bottom: 4.0625rem !important;
}

.ml-65 {
  margin-left: 4.0625rem !important;
}

.pl-65 {
  padding-left: 4.0625rem !important;
}

.m-70 {
  margin: 4.375rem;
}

.p-70 {
  padding: 4.375rem;
}

.mt-70 {
  margin-top: 4.375rem !important;
}

.pt-70 {
  padding-top: 4.375rem !important;
}

.mr-70 {
  margin-right: 4.375rem !important;
}

.pr-70 {
  padding-right: 4.375rem !important;
}

.mb-70 {
  margin-bottom: 4.375rem !important;
}

.pb-70 {
  padding-bottom: 4.375rem !important;
}

.ml-70 {
  margin-left: 4.375rem !important;
}

.pl-70 {
  padding-left: 4.375rem !important;
}

.m-75 {
  margin: 4.6875rem;
}

.p-75 {
  padding: 4.6875rem;
}

.mt-75 {
  margin-top: 4.6875rem !important;
}

.pt-75 {
  padding-top: 4.6875rem !important;
}

.mr-75 {
  margin-right: 4.6875rem !important;
}

.pr-75 {
  padding-right: 4.6875rem !important;
}

.mb-75 {
  margin-bottom: 4.6875rem !important;
}

.pb-75 {
  padding-bottom: 4.6875rem !important;
}

.ml-75 {
  margin-left: 4.6875rem !important;
}

.pl-75 {
  padding-left: 4.6875rem !important;
}

.m-80 {
  margin: 5rem;
}

.p-80 {
  padding: 5rem;
}

.mt-80 {
  margin-top: 5rem !important;
}

.pt-80 {
  padding-top: 5rem !important;
}

.mr-80 {
  margin-right: 5rem !important;
}

.pr-80 {
  padding-right: 5rem !important;
}

.mb-80 {
  margin-bottom: 5rem !important;
}

.pb-80 {
  padding-bottom: 5rem !important;
}

.ml-80 {
  margin-left: 5rem !important;
}

.pl-80 {
  padding-left: 5rem !important;
}

.m-85 {
  margin: 5.3125rem;
}

.p-85 {
  padding: 5.3125rem;
}

.mt-85 {
  margin-top: 5.3125rem !important;
}

.pt-85 {
  padding-top: 5.3125rem !important;
}

.mr-85 {
  margin-right: 5.3125rem !important;
}

.pr-85 {
  padding-right: 5.3125rem !important;
}

.mb-85 {
  margin-bottom: 5.3125rem !important;
}

.pb-85 {
  padding-bottom: 5.3125rem !important;
}

.ml-85 {
  margin-left: 5.3125rem !important;
}

.pl-85 {
  padding-left: 5.3125rem !important;
}

.m-90 {
  margin: 5.625rem;
}

.p-90 {
  padding: 5.625rem;
}

.mt-90 {
  margin-top: 5.625rem !important;
}

.pt-90 {
  padding-top: 5.625rem !important;
}

.mr-90 {
  margin-right: 5.625rem !important;
}

.pr-90 {
  padding-right: 5.625rem !important;
}

.mb-90 {
  margin-bottom: 5.625rem !important;
}

.pb-90 {
  padding-bottom: 5.625rem !important;
}

.ml-90 {
  margin-left: 5.625rem !important;
}

.pl-90 {
  padding-left: 5.625rem !important;
}

.m-95 {
  margin: 5.9375rem;
}

.p-95 {
  padding: 5.9375rem;
}

.mt-95 {
  margin-top: 5.9375rem !important;
}

.pt-95 {
  padding-top: 5.9375rem !important;
}

.mr-95 {
  margin-right: 5.9375rem !important;
}

.pr-95 {
  padding-right: 5.9375rem !important;
}

.mb-95 {
  margin-bottom: 5.9375rem !important;
}

.pb-95 {
  padding-bottom: 5.9375rem !important;
}

.ml-95 {
  margin-left: 5.9375rem !important;
}

.pl-95 {
  padding-left: 5.9375rem !important;
}

.m-100 {
  margin: 6.25rem;
}

.p-100 {
  padding: 6.25rem;
}

.mt-100 {
  margin-top: 6.25rem !important;
}

.pt-100 {
  padding-top: 6.25rem !important;
}

.mr-100 {
  margin-right: 6.25rem !important;
}

.pr-100 {
  padding-right: 6.25rem !important;
}

.mb-100 {
  margin-bottom: 6.25rem !important;
}

.pb-100 {
  padding-bottom: 6.25rem !important;
}

.ml-100 {
  margin-left: 6.25rem !important;
}

.pl-100 {
  padding-left: 6.25rem !important;
}

@media (max-width: 576px) {
  .m-0-xs {
    margin: 0rem;
  }

  .p-0-xs {
    padding: 0rem;
  }

  .mt-0-xs {
    margin-top: 0rem !important;
  }

  .pt-0-xs {
    padding-top: 0rem !important;
  }

  .mr-0-xs {
    margin-right: 0rem !important;
  }

  .pr-0-xs {
    padding-right: 0rem !important;
  }

  .mb-0-xs {
    margin-bottom: 0rem !important;
  }

  .pb-0-xs {
    padding-bottom: 0rem !important;
  }

  .ml-0-xs {
    margin-left: 0rem !important;
  }

  .pl-0-xs {
    padding-left: 0rem !important;
  }

  .m-5-xs {
    margin: 0.3125rem;
  }

  .p-5-xs {
    padding: 0.3125rem;
  }

  .mt-5-xs {
    margin-top: 0.3125rem !important;
  }

  .pt-5-xs {
    padding-top: 0.3125rem !important;
  }

  .mr-5-xs {
    margin-right: 0.3125rem !important;
  }

  .pr-5-xs {
    padding-right: 0.3125rem !important;
  }

  .mb-5-xs {
    margin-bottom: 0.3125rem !important;
  }

  .pb-5-xs {
    padding-bottom: 0.3125rem !important;
  }

  .ml-5-xs {
    margin-left: 0.3125rem !important;
  }

  .pl-5-xs {
    padding-left: 0.3125rem !important;
  }

  .m-10-xs {
    margin: 0.625rem;
  }

  .p-10-xs {
    padding: 0.625rem;
  }

  .mt-10-xs {
    margin-top: 0.625rem !important;
  }

  .pt-10-xs {
    padding-top: 0.625rem !important;
  }

  .mr-10-xs {
    margin-right: 0.625rem !important;
  }

  .pr-10-xs {
    padding-right: 0.625rem !important;
  }

  .mb-10-xs {
    margin-bottom: 0.625rem !important;
  }

  .pb-10-xs {
    padding-bottom: 0.625rem !important;
  }

  .ml-10-xs {
    margin-left: 0.625rem !important;
  }

  .pl-10-xs {
    padding-left: 0.625rem !important;
  }

  .m-15-xs {
    margin: 0.9375rem;
  }

  .p-15-xs {
    padding: 0.9375rem;
  }

  .mt-15-xs {
    margin-top: 0.9375rem !important;
  }

  .pt-15-xs {
    padding-top: 0.9375rem !important;
  }

  .mr-15-xs {
    margin-right: 0.9375rem !important;
  }

  .pr-15-xs {
    padding-right: 0.9375rem !important;
  }

  .mb-15-xs {
    margin-bottom: 0.9375rem !important;
  }

  .pb-15-xs {
    padding-bottom: 0.9375rem !important;
  }

  .ml-15-xs {
    margin-left: 0.9375rem !important;
  }

  .pl-15-xs {
    padding-left: 0.9375rem !important;
  }

  .m-20-xs {
    margin: 1.25rem;
  }

  .p-20-xs {
    padding: 1.25rem;
  }

  .mt-20-xs {
    margin-top: 1.25rem !important;
  }

  .pt-20-xs {
    padding-top: 1.25rem !important;
  }

  .mr-20-xs {
    margin-right: 1.25rem !important;
  }

  .pr-20-xs {
    padding-right: 1.25rem !important;
  }

  .mb-20-xs {
    margin-bottom: 1.25rem !important;
  }

  .pb-20-xs {
    padding-bottom: 1.25rem !important;
  }

  .ml-20-xs {
    margin-left: 1.25rem !important;
  }

  .pl-20-xs {
    padding-left: 1.25rem !important;
  }

  .m-25-xs {
    margin: 1.5625rem;
  }

  .p-25-xs {
    padding: 1.5625rem;
  }

  .mt-25-xs {
    margin-top: 1.5625rem !important;
  }

  .pt-25-xs {
    padding-top: 1.5625rem !important;
  }

  .mr-25-xs {
    margin-right: 1.5625rem !important;
  }

  .pr-25-xs {
    padding-right: 1.5625rem !important;
  }

  .mb-25-xs {
    margin-bottom: 1.5625rem !important;
  }

  .pb-25-xs {
    padding-bottom: 1.5625rem !important;
  }

  .ml-25-xs {
    margin-left: 1.5625rem !important;
  }

  .pl-25-xs {
    padding-left: 1.5625rem !important;
  }

  .m-30-xs {
    margin: 1.875rem;
  }

  .p-30-xs {
    padding: 1.875rem;
  }

  .mt-30-xs {
    margin-top: 1.875rem !important;
  }

  .pt-30-xs {
    padding-top: 1.875rem !important;
  }

  .mr-30-xs {
    margin-right: 1.875rem !important;
  }

  .pr-30-xs {
    padding-right: 1.875rem !important;
  }

  .mb-30-xs {
    margin-bottom: 1.875rem !important;
  }

  .pb-30-xs {
    padding-bottom: 1.875rem !important;
  }

  .ml-30-xs {
    margin-left: 1.875rem !important;
  }

  .pl-30-xs {
    padding-left: 1.875rem !important;
  }

  .m-35-xs {
    margin: 2.1875rem;
  }

  .p-35-xs {
    padding: 2.1875rem;
  }

  .mt-35-xs {
    margin-top: 2.1875rem !important;
  }

  .pt-35-xs {
    padding-top: 2.1875rem !important;
  }

  .mr-35-xs {
    margin-right: 2.1875rem !important;
  }

  .pr-35-xs {
    padding-right: 2.1875rem !important;
  }

  .mb-35-xs {
    margin-bottom: 2.1875rem !important;
  }

  .pb-35-xs {
    padding-bottom: 2.1875rem !important;
  }

  .ml-35-xs {
    margin-left: 2.1875rem !important;
  }

  .pl-35-xs {
    padding-left: 2.1875rem !important;
  }

  .m-40-xs {
    margin: 2.5rem;
  }

  .p-40-xs {
    padding: 2.5rem;
  }

  .mt-40-xs {
    margin-top: 2.5rem !important;
  }

  .pt-40-xs {
    padding-top: 2.5rem !important;
  }

  .mr-40-xs {
    margin-right: 2.5rem !important;
  }

  .pr-40-xs {
    padding-right: 2.5rem !important;
  }

  .mb-40-xs {
    margin-bottom: 2.5rem !important;
  }

  .pb-40-xs {
    padding-bottom: 2.5rem !important;
  }

  .ml-40-xs {
    margin-left: 2.5rem !important;
  }

  .pl-40-xs {
    padding-left: 2.5rem !important;
  }

  .m-45-xs {
    margin: 2.8125rem;
  }

  .p-45-xs {
    padding: 2.8125rem;
  }

  .mt-45-xs {
    margin-top: 2.8125rem !important;
  }

  .pt-45-xs {
    padding-top: 2.8125rem !important;
  }

  .mr-45-xs {
    margin-right: 2.8125rem !important;
  }

  .pr-45-xs {
    padding-right: 2.8125rem !important;
  }

  .mb-45-xs {
    margin-bottom: 2.8125rem !important;
  }

  .pb-45-xs {
    padding-bottom: 2.8125rem !important;
  }

  .ml-45-xs {
    margin-left: 2.8125rem !important;
  }

  .pl-45-xs {
    padding-left: 2.8125rem !important;
  }

  .m-50-xs {
    margin: 3.125rem;
  }

  .p-50-xs {
    padding: 3.125rem;
  }

  .mt-50-xs {
    margin-top: 3.125rem !important;
  }

  .pt-50-xs {
    padding-top: 3.125rem !important;
  }

  .mr-50-xs {
    margin-right: 3.125rem !important;
  }

  .pr-50-xs {
    padding-right: 3.125rem !important;
  }

  .mb-50-xs {
    margin-bottom: 3.125rem !important;
  }

  .pb-50-xs {
    padding-bottom: 3.125rem !important;
  }

  .ml-50-xs {
    margin-left: 3.125rem !important;
  }

  .pl-50-xs {
    padding-left: 3.125rem !important;
  }

  .m-55-xs {
    margin: 3.4375rem;
  }

  .p-55-xs {
    padding: 3.4375rem;
  }

  .mt-55-xs {
    margin-top: 3.4375rem !important;
  }

  .pt-55-xs {
    padding-top: 3.4375rem !important;
  }

  .mr-55-xs {
    margin-right: 3.4375rem !important;
  }

  .pr-55-xs {
    padding-right: 3.4375rem !important;
  }

  .mb-55-xs {
    margin-bottom: 3.4375rem !important;
  }

  .pb-55-xs {
    padding-bottom: 3.4375rem !important;
  }

  .ml-55-xs {
    margin-left: 3.4375rem !important;
  }

  .pl-55-xs {
    padding-left: 3.4375rem !important;
  }

  .m-60-xs {
    margin: 3.75rem;
  }

  .p-60-xs {
    padding: 3.75rem;
  }

  .mt-60-xs {
    margin-top: 3.75rem !important;
  }

  .pt-60-xs {
    padding-top: 3.75rem !important;
  }

  .mr-60-xs {
    margin-right: 3.75rem !important;
  }

  .pr-60-xs {
    padding-right: 3.75rem !important;
  }

  .mb-60-xs {
    margin-bottom: 3.75rem !important;
  }

  .pb-60-xs {
    padding-bottom: 3.75rem !important;
  }

  .ml-60-xs {
    margin-left: 3.75rem !important;
  }

  .pl-60-xs {
    padding-left: 3.75rem !important;
  }

  .m-65-xs {
    margin: 4.0625rem;
  }

  .p-65-xs {
    padding: 4.0625rem;
  }

  .mt-65-xs {
    margin-top: 4.0625rem !important;
  }

  .pt-65-xs {
    padding-top: 4.0625rem !important;
  }

  .mr-65-xs {
    margin-right: 4.0625rem !important;
  }

  .pr-65-xs {
    padding-right: 4.0625rem !important;
  }

  .mb-65-xs {
    margin-bottom: 4.0625rem !important;
  }

  .pb-65-xs {
    padding-bottom: 4.0625rem !important;
  }

  .ml-65-xs {
    margin-left: 4.0625rem !important;
  }

  .pl-65-xs {
    padding-left: 4.0625rem !important;
  }

  .m-70-xs {
    margin: 4.375rem;
  }

  .p-70-xs {
    padding: 4.375rem;
  }

  .mt-70-xs {
    margin-top: 4.375rem !important;
  }

  .pt-70-xs {
    padding-top: 4.375rem !important;
  }

  .mr-70-xs {
    margin-right: 4.375rem !important;
  }

  .pr-70-xs {
    padding-right: 4.375rem !important;
  }

  .mb-70-xs {
    margin-bottom: 4.375rem !important;
  }

  .pb-70-xs {
    padding-bottom: 4.375rem !important;
  }

  .ml-70-xs {
    margin-left: 4.375rem !important;
  }

  .pl-70-xs {
    padding-left: 4.375rem !important;
  }

  .m-75-xs {
    margin: 4.6875rem;
  }

  .p-75-xs {
    padding: 4.6875rem;
  }

  .mt-75-xs {
    margin-top: 4.6875rem !important;
  }

  .pt-75-xs {
    padding-top: 4.6875rem !important;
  }

  .mr-75-xs {
    margin-right: 4.6875rem !important;
  }

  .pr-75-xs {
    padding-right: 4.6875rem !important;
  }

  .mb-75-xs {
    margin-bottom: 4.6875rem !important;
  }

  .pb-75-xs {
    padding-bottom: 4.6875rem !important;
  }

  .ml-75-xs {
    margin-left: 4.6875rem !important;
  }

  .pl-75-xs {
    padding-left: 4.6875rem !important;
  }

  .m-80-xs {
    margin: 5rem;
  }

  .p-80-xs {
    padding: 5rem;
  }

  .mt-80-xs {
    margin-top: 5rem !important;
  }

  .pt-80-xs {
    padding-top: 5rem !important;
  }

  .mr-80-xs {
    margin-right: 5rem !important;
  }

  .pr-80-xs {
    padding-right: 5rem !important;
  }

  .mb-80-xs {
    margin-bottom: 5rem !important;
  }

  .pb-80-xs {
    padding-bottom: 5rem !important;
  }

  .ml-80-xs {
    margin-left: 5rem !important;
  }

  .pl-80-xs {
    padding-left: 5rem !important;
  }

  .m-85-xs {
    margin: 5.3125rem;
  }

  .p-85-xs {
    padding: 5.3125rem;
  }

  .mt-85-xs {
    margin-top: 5.3125rem !important;
  }

  .pt-85-xs {
    padding-top: 5.3125rem !important;
  }

  .mr-85-xs {
    margin-right: 5.3125rem !important;
  }

  .pr-85-xs {
    padding-right: 5.3125rem !important;
  }

  .mb-85-xs {
    margin-bottom: 5.3125rem !important;
  }

  .pb-85-xs {
    padding-bottom: 5.3125rem !important;
  }

  .ml-85-xs {
    margin-left: 5.3125rem !important;
  }

  .pl-85-xs {
    padding-left: 5.3125rem !important;
  }

  .m-90-xs {
    margin: 5.625rem;
  }

  .p-90-xs {
    padding: 5.625rem;
  }

  .mt-90-xs {
    margin-top: 5.625rem !important;
  }

  .pt-90-xs {
    padding-top: 5.625rem !important;
  }

  .mr-90-xs {
    margin-right: 5.625rem !important;
  }

  .pr-90-xs {
    padding-right: 5.625rem !important;
  }

  .mb-90-xs {
    margin-bottom: 5.625rem !important;
  }

  .pb-90-xs {
    padding-bottom: 5.625rem !important;
  }

  .ml-90-xs {
    margin-left: 5.625rem !important;
  }

  .pl-90-xs {
    padding-left: 5.625rem !important;
  }

  .m-95-xs {
    margin: 5.9375rem;
  }

  .p-95-xs {
    padding: 5.9375rem;
  }

  .mt-95-xs {
    margin-top: 5.9375rem !important;
  }

  .pt-95-xs {
    padding-top: 5.9375rem !important;
  }

  .mr-95-xs {
    margin-right: 5.9375rem !important;
  }

  .pr-95-xs {
    padding-right: 5.9375rem !important;
  }

  .mb-95-xs {
    margin-bottom: 5.9375rem !important;
  }

  .pb-95-xs {
    padding-bottom: 5.9375rem !important;
  }

  .ml-95-xs {
    margin-left: 5.9375rem !important;
  }

  .pl-95-xs {
    padding-left: 5.9375rem !important;
  }

  .m-100-xs {
    margin: 6.25rem;
  }

  .p-100-xs {
    padding: 6.25rem;
  }

  .mt-100-xs {
    margin-top: 6.25rem !important;
  }

  .pt-100-xs {
    padding-top: 6.25rem !important;
  }

  .mr-100-xs {
    margin-right: 6.25rem !important;
  }

  .pr-100-xs {
    padding-right: 6.25rem !important;
  }

  .mb-100-xs {
    margin-bottom: 6.25rem !important;
  }

  .pb-100-xs {
    padding-bottom: 6.25rem !important;
  }

  .ml-100-xs {
    margin-left: 6.25rem !important;
  }

  .pl-100-xs {
    padding-left: 6.25rem !important;
  }
}
@media (max-width: 640px) {
  .m-0-sm {
    margin: 0rem;
  }

  .p-0-sm {
    padding: 0rem;
  }

  .mt-0-sm {
    margin-top: 0rem !important;
  }

  .pt-0-sm {
    padding-top: 0rem !important;
  }

  .mr-0-sm {
    margin-right: 0rem !important;
  }

  .pr-0-sm {
    padding-right: 0rem !important;
  }

  .mb-0-sm {
    margin-bottom: 0rem !important;
  }

  .pb-0-sm {
    padding-bottom: 0rem !important;
  }

  .ml-0-sm {
    margin-left: 0rem !important;
  }

  .pl-0-sm {
    padding-left: 0rem !important;
  }

  .m-5-sm {
    margin: 0.3125rem;
  }

  .p-5-sm {
    padding: 0.3125rem;
  }

  .mt-5-sm {
    margin-top: 0.3125rem !important;
  }

  .pt-5-sm {
    padding-top: 0.3125rem !important;
  }

  .mr-5-sm {
    margin-right: 0.3125rem !important;
  }

  .pr-5-sm {
    padding-right: 0.3125rem !important;
  }

  .mb-5-sm {
    margin-bottom: 0.3125rem !important;
  }

  .pb-5-sm {
    padding-bottom: 0.3125rem !important;
  }

  .ml-5-sm {
    margin-left: 0.3125rem !important;
  }

  .pl-5-sm {
    padding-left: 0.3125rem !important;
  }

  .m-10-sm {
    margin: 0.625rem;
  }

  .p-10-sm {
    padding: 0.625rem;
  }

  .mt-10-sm {
    margin-top: 0.625rem !important;
  }

  .pt-10-sm {
    padding-top: 0.625rem !important;
  }

  .mr-10-sm {
    margin-right: 0.625rem !important;
  }

  .pr-10-sm {
    padding-right: 0.625rem !important;
  }

  .mb-10-sm {
    margin-bottom: 0.625rem !important;
  }

  .pb-10-sm {
    padding-bottom: 0.625rem !important;
  }

  .ml-10-sm {
    margin-left: 0.625rem !important;
  }

  .pl-10-sm {
    padding-left: 0.625rem !important;
  }

  .m-15-sm {
    margin: 0.9375rem;
  }

  .p-15-sm {
    padding: 0.9375rem;
  }

  .mt-15-sm {
    margin-top: 0.9375rem !important;
  }

  .pt-15-sm {
    padding-top: 0.9375rem !important;
  }

  .mr-15-sm {
    margin-right: 0.9375rem !important;
  }

  .pr-15-sm {
    padding-right: 0.9375rem !important;
  }

  .mb-15-sm {
    margin-bottom: 0.9375rem !important;
  }

  .pb-15-sm {
    padding-bottom: 0.9375rem !important;
  }

  .ml-15-sm {
    margin-left: 0.9375rem !important;
  }

  .pl-15-sm {
    padding-left: 0.9375rem !important;
  }

  .m-20-sm {
    margin: 1.25rem;
  }

  .p-20-sm {
    padding: 1.25rem;
  }

  .mt-20-sm {
    margin-top: 1.25rem !important;
  }

  .pt-20-sm {
    padding-top: 1.25rem !important;
  }

  .mr-20-sm {
    margin-right: 1.25rem !important;
  }

  .pr-20-sm {
    padding-right: 1.25rem !important;
  }

  .mb-20-sm {
    margin-bottom: 1.25rem !important;
  }

  .pb-20-sm {
    padding-bottom: 1.25rem !important;
  }

  .ml-20-sm {
    margin-left: 1.25rem !important;
  }

  .pl-20-sm {
    padding-left: 1.25rem !important;
  }

  .m-25-sm {
    margin: 1.5625rem;
  }

  .p-25-sm {
    padding: 1.5625rem;
  }

  .mt-25-sm {
    margin-top: 1.5625rem !important;
  }

  .pt-25-sm {
    padding-top: 1.5625rem !important;
  }

  .mr-25-sm {
    margin-right: 1.5625rem !important;
  }

  .pr-25-sm {
    padding-right: 1.5625rem !important;
  }

  .mb-25-sm {
    margin-bottom: 1.5625rem !important;
  }

  .pb-25-sm {
    padding-bottom: 1.5625rem !important;
  }

  .ml-25-sm {
    margin-left: 1.5625rem !important;
  }

  .pl-25-sm {
    padding-left: 1.5625rem !important;
  }

  .m-30-sm {
    margin: 1.875rem;
  }

  .p-30-sm {
    padding: 1.875rem;
  }

  .mt-30-sm {
    margin-top: 1.875rem !important;
  }

  .pt-30-sm {
    padding-top: 1.875rem !important;
  }

  .mr-30-sm {
    margin-right: 1.875rem !important;
  }

  .pr-30-sm {
    padding-right: 1.875rem !important;
  }

  .mb-30-sm {
    margin-bottom: 1.875rem !important;
  }

  .pb-30-sm {
    padding-bottom: 1.875rem !important;
  }

  .ml-30-sm {
    margin-left: 1.875rem !important;
  }

  .pl-30-sm {
    padding-left: 1.875rem !important;
  }

  .m-35-sm {
    margin: 2.1875rem;
  }

  .p-35-sm {
    padding: 2.1875rem;
  }

  .mt-35-sm {
    margin-top: 2.1875rem !important;
  }

  .pt-35-sm {
    padding-top: 2.1875rem !important;
  }

  .mr-35-sm {
    margin-right: 2.1875rem !important;
  }

  .pr-35-sm {
    padding-right: 2.1875rem !important;
  }

  .mb-35-sm {
    margin-bottom: 2.1875rem !important;
  }

  .pb-35-sm {
    padding-bottom: 2.1875rem !important;
  }

  .ml-35-sm {
    margin-left: 2.1875rem !important;
  }

  .pl-35-sm {
    padding-left: 2.1875rem !important;
  }

  .m-40-sm {
    margin: 2.5rem;
  }

  .p-40-sm {
    padding: 2.5rem;
  }

  .mt-40-sm {
    margin-top: 2.5rem !important;
  }

  .pt-40-sm {
    padding-top: 2.5rem !important;
  }

  .mr-40-sm {
    margin-right: 2.5rem !important;
  }

  .pr-40-sm {
    padding-right: 2.5rem !important;
  }

  .mb-40-sm {
    margin-bottom: 2.5rem !important;
  }

  .pb-40-sm {
    padding-bottom: 2.5rem !important;
  }

  .ml-40-sm {
    margin-left: 2.5rem !important;
  }

  .pl-40-sm {
    padding-left: 2.5rem !important;
  }

  .m-45-sm {
    margin: 2.8125rem;
  }

  .p-45-sm {
    padding: 2.8125rem;
  }

  .mt-45-sm {
    margin-top: 2.8125rem !important;
  }

  .pt-45-sm {
    padding-top: 2.8125rem !important;
  }

  .mr-45-sm {
    margin-right: 2.8125rem !important;
  }

  .pr-45-sm {
    padding-right: 2.8125rem !important;
  }

  .mb-45-sm {
    margin-bottom: 2.8125rem !important;
  }

  .pb-45-sm {
    padding-bottom: 2.8125rem !important;
  }

  .ml-45-sm {
    margin-left: 2.8125rem !important;
  }

  .pl-45-sm {
    padding-left: 2.8125rem !important;
  }

  .m-50-sm {
    margin: 3.125rem;
  }

  .p-50-sm {
    padding: 3.125rem;
  }

  .mt-50-sm {
    margin-top: 3.125rem !important;
  }

  .pt-50-sm {
    padding-top: 3.125rem !important;
  }

  .mr-50-sm {
    margin-right: 3.125rem !important;
  }

  .pr-50-sm {
    padding-right: 3.125rem !important;
  }

  .mb-50-sm {
    margin-bottom: 3.125rem !important;
  }

  .pb-50-sm {
    padding-bottom: 3.125rem !important;
  }

  .ml-50-sm {
    margin-left: 3.125rem !important;
  }

  .pl-50-sm {
    padding-left: 3.125rem !important;
  }

  .m-55-sm {
    margin: 3.4375rem;
  }

  .p-55-sm {
    padding: 3.4375rem;
  }

  .mt-55-sm {
    margin-top: 3.4375rem !important;
  }

  .pt-55-sm {
    padding-top: 3.4375rem !important;
  }

  .mr-55-sm {
    margin-right: 3.4375rem !important;
  }

  .pr-55-sm {
    padding-right: 3.4375rem !important;
  }

  .mb-55-sm {
    margin-bottom: 3.4375rem !important;
  }

  .pb-55-sm {
    padding-bottom: 3.4375rem !important;
  }

  .ml-55-sm {
    margin-left: 3.4375rem !important;
  }

  .pl-55-sm {
    padding-left: 3.4375rem !important;
  }

  .m-60-sm {
    margin: 3.75rem;
  }

  .p-60-sm {
    padding: 3.75rem;
  }

  .mt-60-sm {
    margin-top: 3.75rem !important;
  }

  .pt-60-sm {
    padding-top: 3.75rem !important;
  }

  .mr-60-sm {
    margin-right: 3.75rem !important;
  }

  .pr-60-sm {
    padding-right: 3.75rem !important;
  }

  .mb-60-sm {
    margin-bottom: 3.75rem !important;
  }

  .pb-60-sm {
    padding-bottom: 3.75rem !important;
  }

  .ml-60-sm {
    margin-left: 3.75rem !important;
  }

  .pl-60-sm {
    padding-left: 3.75rem !important;
  }

  .m-65-sm {
    margin: 4.0625rem;
  }

  .p-65-sm {
    padding: 4.0625rem;
  }

  .mt-65-sm {
    margin-top: 4.0625rem !important;
  }

  .pt-65-sm {
    padding-top: 4.0625rem !important;
  }

  .mr-65-sm {
    margin-right: 4.0625rem !important;
  }

  .pr-65-sm {
    padding-right: 4.0625rem !important;
  }

  .mb-65-sm {
    margin-bottom: 4.0625rem !important;
  }

  .pb-65-sm {
    padding-bottom: 4.0625rem !important;
  }

  .ml-65-sm {
    margin-left: 4.0625rem !important;
  }

  .pl-65-sm {
    padding-left: 4.0625rem !important;
  }

  .m-70-sm {
    margin: 4.375rem;
  }

  .p-70-sm {
    padding: 4.375rem;
  }

  .mt-70-sm {
    margin-top: 4.375rem !important;
  }

  .pt-70-sm {
    padding-top: 4.375rem !important;
  }

  .mr-70-sm {
    margin-right: 4.375rem !important;
  }

  .pr-70-sm {
    padding-right: 4.375rem !important;
  }

  .mb-70-sm {
    margin-bottom: 4.375rem !important;
  }

  .pb-70-sm {
    padding-bottom: 4.375rem !important;
  }

  .ml-70-sm {
    margin-left: 4.375rem !important;
  }

  .pl-70-sm {
    padding-left: 4.375rem !important;
  }

  .m-75-sm {
    margin: 4.6875rem;
  }

  .p-75-sm {
    padding: 4.6875rem;
  }

  .mt-75-sm {
    margin-top: 4.6875rem !important;
  }

  .pt-75-sm {
    padding-top: 4.6875rem !important;
  }

  .mr-75-sm {
    margin-right: 4.6875rem !important;
  }

  .pr-75-sm {
    padding-right: 4.6875rem !important;
  }

  .mb-75-sm {
    margin-bottom: 4.6875rem !important;
  }

  .pb-75-sm {
    padding-bottom: 4.6875rem !important;
  }

  .ml-75-sm {
    margin-left: 4.6875rem !important;
  }

  .pl-75-sm {
    padding-left: 4.6875rem !important;
  }

  .m-80-sm {
    margin: 5rem;
  }

  .p-80-sm {
    padding: 5rem;
  }

  .mt-80-sm {
    margin-top: 5rem !important;
  }

  .pt-80-sm {
    padding-top: 5rem !important;
  }

  .mr-80-sm {
    margin-right: 5rem !important;
  }

  .pr-80-sm {
    padding-right: 5rem !important;
  }

  .mb-80-sm {
    margin-bottom: 5rem !important;
  }

  .pb-80-sm {
    padding-bottom: 5rem !important;
  }

  .ml-80-sm {
    margin-left: 5rem !important;
  }

  .pl-80-sm {
    padding-left: 5rem !important;
  }

  .m-85-sm {
    margin: 5.3125rem;
  }

  .p-85-sm {
    padding: 5.3125rem;
  }

  .mt-85-sm {
    margin-top: 5.3125rem !important;
  }

  .pt-85-sm {
    padding-top: 5.3125rem !important;
  }

  .mr-85-sm {
    margin-right: 5.3125rem !important;
  }

  .pr-85-sm {
    padding-right: 5.3125rem !important;
  }

  .mb-85-sm {
    margin-bottom: 5.3125rem !important;
  }

  .pb-85-sm {
    padding-bottom: 5.3125rem !important;
  }

  .ml-85-sm {
    margin-left: 5.3125rem !important;
  }

  .pl-85-sm {
    padding-left: 5.3125rem !important;
  }

  .m-90-sm {
    margin: 5.625rem;
  }

  .p-90-sm {
    padding: 5.625rem;
  }

  .mt-90-sm {
    margin-top: 5.625rem !important;
  }

  .pt-90-sm {
    padding-top: 5.625rem !important;
  }

  .mr-90-sm {
    margin-right: 5.625rem !important;
  }

  .pr-90-sm {
    padding-right: 5.625rem !important;
  }

  .mb-90-sm {
    margin-bottom: 5.625rem !important;
  }

  .pb-90-sm {
    padding-bottom: 5.625rem !important;
  }

  .ml-90-sm {
    margin-left: 5.625rem !important;
  }

  .pl-90-sm {
    padding-left: 5.625rem !important;
  }

  .m-95-sm {
    margin: 5.9375rem;
  }

  .p-95-sm {
    padding: 5.9375rem;
  }

  .mt-95-sm {
    margin-top: 5.9375rem !important;
  }

  .pt-95-sm {
    padding-top: 5.9375rem !important;
  }

  .mr-95-sm {
    margin-right: 5.9375rem !important;
  }

  .pr-95-sm {
    padding-right: 5.9375rem !important;
  }

  .mb-95-sm {
    margin-bottom: 5.9375rem !important;
  }

  .pb-95-sm {
    padding-bottom: 5.9375rem !important;
  }

  .ml-95-sm {
    margin-left: 5.9375rem !important;
  }

  .pl-95-sm {
    padding-left: 5.9375rem !important;
  }

  .m-100-sm {
    margin: 6.25rem;
  }

  .p-100-sm {
    padding: 6.25rem;
  }

  .mt-100-sm {
    margin-top: 6.25rem !important;
  }

  .pt-100-sm {
    padding-top: 6.25rem !important;
  }

  .mr-100-sm {
    margin-right: 6.25rem !important;
  }

  .pr-100-sm {
    padding-right: 6.25rem !important;
  }

  .mb-100-sm {
    margin-bottom: 6.25rem !important;
  }

  .pb-100-sm {
    padding-bottom: 6.25rem !important;
  }

  .ml-100-sm {
    margin-left: 6.25rem !important;
  }

  .pl-100-sm {
    padding-left: 6.25rem !important;
  }
}
@media (max-width: 768px) {
  .m-0-md {
    margin: 0rem;
  }

  .p-0-md {
    padding: 0rem;
  }

  .mt-0-md {
    margin-top: 0rem !important;
  }

  .pt-0-md {
    padding-top: 0rem !important;
  }

  .mr-0-md {
    margin-right: 0rem !important;
  }

  .pr-0-md {
    padding-right: 0rem !important;
  }

  .mb-0-md {
    margin-bottom: 0rem !important;
  }

  .pb-0-md {
    padding-bottom: 0rem !important;
  }

  .ml-0-md {
    margin-left: 0rem !important;
  }

  .pl-0-md {
    padding-left: 0rem !important;
  }

  .m-5-md {
    margin: 0.3125rem;
  }

  .p-5-md {
    padding: 0.3125rem;
  }

  .mt-5-md {
    margin-top: 0.3125rem !important;
  }

  .pt-5-md {
    padding-top: 0.3125rem !important;
  }

  .mr-5-md {
    margin-right: 0.3125rem !important;
  }

  .pr-5-md {
    padding-right: 0.3125rem !important;
  }

  .mb-5-md {
    margin-bottom: 0.3125rem !important;
  }

  .pb-5-md {
    padding-bottom: 0.3125rem !important;
  }

  .ml-5-md {
    margin-left: 0.3125rem !important;
  }

  .pl-5-md {
    padding-left: 0.3125rem !important;
  }

  .m-10-md {
    margin: 0.625rem;
  }

  .p-10-md {
    padding: 0.625rem;
  }

  .mt-10-md {
    margin-top: 0.625rem !important;
  }

  .pt-10-md {
    padding-top: 0.625rem !important;
  }

  .mr-10-md {
    margin-right: 0.625rem !important;
  }

  .pr-10-md {
    padding-right: 0.625rem !important;
  }

  .mb-10-md {
    margin-bottom: 0.625rem !important;
  }

  .pb-10-md {
    padding-bottom: 0.625rem !important;
  }

  .ml-10-md {
    margin-left: 0.625rem !important;
  }

  .pl-10-md {
    padding-left: 0.625rem !important;
  }

  .m-15-md {
    margin: 0.9375rem;
  }

  .p-15-md {
    padding: 0.9375rem;
  }

  .mt-15-md {
    margin-top: 0.9375rem !important;
  }

  .pt-15-md {
    padding-top: 0.9375rem !important;
  }

  .mr-15-md {
    margin-right: 0.9375rem !important;
  }

  .pr-15-md {
    padding-right: 0.9375rem !important;
  }

  .mb-15-md {
    margin-bottom: 0.9375rem !important;
  }

  .pb-15-md {
    padding-bottom: 0.9375rem !important;
  }

  .ml-15-md {
    margin-left: 0.9375rem !important;
  }

  .pl-15-md {
    padding-left: 0.9375rem !important;
  }

  .m-20-md {
    margin: 1.25rem;
  }

  .p-20-md {
    padding: 1.25rem;
  }

  .mt-20-md {
    margin-top: 1.25rem !important;
  }

  .pt-20-md {
    padding-top: 1.25rem !important;
  }

  .mr-20-md {
    margin-right: 1.25rem !important;
  }

  .pr-20-md {
    padding-right: 1.25rem !important;
  }

  .mb-20-md {
    margin-bottom: 1.25rem !important;
  }

  .pb-20-md {
    padding-bottom: 1.25rem !important;
  }

  .ml-20-md {
    margin-left: 1.25rem !important;
  }

  .pl-20-md {
    padding-left: 1.25rem !important;
  }

  .m-25-md {
    margin: 1.5625rem;
  }

  .p-25-md {
    padding: 1.5625rem;
  }

  .mt-25-md {
    margin-top: 1.5625rem !important;
  }

  .pt-25-md {
    padding-top: 1.5625rem !important;
  }

  .mr-25-md {
    margin-right: 1.5625rem !important;
  }

  .pr-25-md {
    padding-right: 1.5625rem !important;
  }

  .mb-25-md {
    margin-bottom: 1.5625rem !important;
  }

  .pb-25-md {
    padding-bottom: 1.5625rem !important;
  }

  .ml-25-md {
    margin-left: 1.5625rem !important;
  }

  .pl-25-md {
    padding-left: 1.5625rem !important;
  }

  .m-30-md {
    margin: 1.875rem;
  }

  .p-30-md {
    padding: 1.875rem;
  }

  .mt-30-md {
    margin-top: 1.875rem !important;
  }

  .pt-30-md {
    padding-top: 1.875rem !important;
  }

  .mr-30-md {
    margin-right: 1.875rem !important;
  }

  .pr-30-md {
    padding-right: 1.875rem !important;
  }

  .mb-30-md {
    margin-bottom: 1.875rem !important;
  }

  .pb-30-md {
    padding-bottom: 1.875rem !important;
  }

  .ml-30-md {
    margin-left: 1.875rem !important;
  }

  .pl-30-md {
    padding-left: 1.875rem !important;
  }

  .m-35-md {
    margin: 2.1875rem;
  }

  .p-35-md {
    padding: 2.1875rem;
  }

  .mt-35-md {
    margin-top: 2.1875rem !important;
  }

  .pt-35-md {
    padding-top: 2.1875rem !important;
  }

  .mr-35-md {
    margin-right: 2.1875rem !important;
  }

  .pr-35-md {
    padding-right: 2.1875rem !important;
  }

  .mb-35-md {
    margin-bottom: 2.1875rem !important;
  }

  .pb-35-md {
    padding-bottom: 2.1875rem !important;
  }

  .ml-35-md {
    margin-left: 2.1875rem !important;
  }

  .pl-35-md {
    padding-left: 2.1875rem !important;
  }

  .m-40-md {
    margin: 2.5rem;
  }

  .p-40-md {
    padding: 2.5rem;
  }

  .mt-40-md {
    margin-top: 2.5rem !important;
  }

  .pt-40-md {
    padding-top: 2.5rem !important;
  }

  .mr-40-md {
    margin-right: 2.5rem !important;
  }

  .pr-40-md {
    padding-right: 2.5rem !important;
  }

  .mb-40-md {
    margin-bottom: 2.5rem !important;
  }

  .pb-40-md {
    padding-bottom: 2.5rem !important;
  }

  .ml-40-md {
    margin-left: 2.5rem !important;
  }

  .pl-40-md {
    padding-left: 2.5rem !important;
  }

  .m-45-md {
    margin: 2.8125rem;
  }

  .p-45-md {
    padding: 2.8125rem;
  }

  .mt-45-md {
    margin-top: 2.8125rem !important;
  }

  .pt-45-md {
    padding-top: 2.8125rem !important;
  }

  .mr-45-md {
    margin-right: 2.8125rem !important;
  }

  .pr-45-md {
    padding-right: 2.8125rem !important;
  }

  .mb-45-md {
    margin-bottom: 2.8125rem !important;
  }

  .pb-45-md {
    padding-bottom: 2.8125rem !important;
  }

  .ml-45-md {
    margin-left: 2.8125rem !important;
  }

  .pl-45-md {
    padding-left: 2.8125rem !important;
  }

  .m-50-md {
    margin: 3.125rem;
  }

  .p-50-md {
    padding: 3.125rem;
  }

  .mt-50-md {
    margin-top: 3.125rem !important;
  }

  .pt-50-md {
    padding-top: 3.125rem !important;
  }

  .mr-50-md {
    margin-right: 3.125rem !important;
  }

  .pr-50-md {
    padding-right: 3.125rem !important;
  }

  .mb-50-md {
    margin-bottom: 3.125rem !important;
  }

  .pb-50-md {
    padding-bottom: 3.125rem !important;
  }

  .ml-50-md {
    margin-left: 3.125rem !important;
  }

  .pl-50-md {
    padding-left: 3.125rem !important;
  }

  .m-55-md {
    margin: 3.4375rem;
  }

  .p-55-md {
    padding: 3.4375rem;
  }

  .mt-55-md {
    margin-top: 3.4375rem !important;
  }

  .pt-55-md {
    padding-top: 3.4375rem !important;
  }

  .mr-55-md {
    margin-right: 3.4375rem !important;
  }

  .pr-55-md {
    padding-right: 3.4375rem !important;
  }

  .mb-55-md {
    margin-bottom: 3.4375rem !important;
  }

  .pb-55-md {
    padding-bottom: 3.4375rem !important;
  }

  .ml-55-md {
    margin-left: 3.4375rem !important;
  }

  .pl-55-md {
    padding-left: 3.4375rem !important;
  }

  .m-60-md {
    margin: 3.75rem;
  }

  .p-60-md {
    padding: 3.75rem;
  }

  .mt-60-md {
    margin-top: 3.75rem !important;
  }

  .pt-60-md {
    padding-top: 3.75rem !important;
  }

  .mr-60-md {
    margin-right: 3.75rem !important;
  }

  .pr-60-md {
    padding-right: 3.75rem !important;
  }

  .mb-60-md {
    margin-bottom: 3.75rem !important;
  }

  .pb-60-md {
    padding-bottom: 3.75rem !important;
  }

  .ml-60-md {
    margin-left: 3.75rem !important;
  }

  .pl-60-md {
    padding-left: 3.75rem !important;
  }

  .m-65-md {
    margin: 4.0625rem;
  }

  .p-65-md {
    padding: 4.0625rem;
  }

  .mt-65-md {
    margin-top: 4.0625rem !important;
  }

  .pt-65-md {
    padding-top: 4.0625rem !important;
  }

  .mr-65-md {
    margin-right: 4.0625rem !important;
  }

  .pr-65-md {
    padding-right: 4.0625rem !important;
  }

  .mb-65-md {
    margin-bottom: 4.0625rem !important;
  }

  .pb-65-md {
    padding-bottom: 4.0625rem !important;
  }

  .ml-65-md {
    margin-left: 4.0625rem !important;
  }

  .pl-65-md {
    padding-left: 4.0625rem !important;
  }

  .m-70-md {
    margin: 4.375rem;
  }

  .p-70-md {
    padding: 4.375rem;
  }

  .mt-70-md {
    margin-top: 4.375rem !important;
  }

  .pt-70-md {
    padding-top: 4.375rem !important;
  }

  .mr-70-md {
    margin-right: 4.375rem !important;
  }

  .pr-70-md {
    padding-right: 4.375rem !important;
  }

  .mb-70-md {
    margin-bottom: 4.375rem !important;
  }

  .pb-70-md {
    padding-bottom: 4.375rem !important;
  }

  .ml-70-md {
    margin-left: 4.375rem !important;
  }

  .pl-70-md {
    padding-left: 4.375rem !important;
  }

  .m-75-md {
    margin: 4.6875rem;
  }

  .p-75-md {
    padding: 4.6875rem;
  }

  .mt-75-md {
    margin-top: 4.6875rem !important;
  }

  .pt-75-md {
    padding-top: 4.6875rem !important;
  }

  .mr-75-md {
    margin-right: 4.6875rem !important;
  }

  .pr-75-md {
    padding-right: 4.6875rem !important;
  }

  .mb-75-md {
    margin-bottom: 4.6875rem !important;
  }

  .pb-75-md {
    padding-bottom: 4.6875rem !important;
  }

  .ml-75-md {
    margin-left: 4.6875rem !important;
  }

  .pl-75-md {
    padding-left: 4.6875rem !important;
  }

  .m-80-md {
    margin: 5rem;
  }

  .p-80-md {
    padding: 5rem;
  }

  .mt-80-md {
    margin-top: 5rem !important;
  }

  .pt-80-md {
    padding-top: 5rem !important;
  }

  .mr-80-md {
    margin-right: 5rem !important;
  }

  .pr-80-md {
    padding-right: 5rem !important;
  }

  .mb-80-md {
    margin-bottom: 5rem !important;
  }

  .pb-80-md {
    padding-bottom: 5rem !important;
  }

  .ml-80-md {
    margin-left: 5rem !important;
  }

  .pl-80-md {
    padding-left: 5rem !important;
  }

  .m-85-md {
    margin: 5.3125rem;
  }

  .p-85-md {
    padding: 5.3125rem;
  }

  .mt-85-md {
    margin-top: 5.3125rem !important;
  }

  .pt-85-md {
    padding-top: 5.3125rem !important;
  }

  .mr-85-md {
    margin-right: 5.3125rem !important;
  }

  .pr-85-md {
    padding-right: 5.3125rem !important;
  }

  .mb-85-md {
    margin-bottom: 5.3125rem !important;
  }

  .pb-85-md {
    padding-bottom: 5.3125rem !important;
  }

  .ml-85-md {
    margin-left: 5.3125rem !important;
  }

  .pl-85-md {
    padding-left: 5.3125rem !important;
  }

  .m-90-md {
    margin: 5.625rem;
  }

  .p-90-md {
    padding: 5.625rem;
  }

  .mt-90-md {
    margin-top: 5.625rem !important;
  }

  .pt-90-md {
    padding-top: 5.625rem !important;
  }

  .mr-90-md {
    margin-right: 5.625rem !important;
  }

  .pr-90-md {
    padding-right: 5.625rem !important;
  }

  .mb-90-md {
    margin-bottom: 5.625rem !important;
  }

  .pb-90-md {
    padding-bottom: 5.625rem !important;
  }

  .ml-90-md {
    margin-left: 5.625rem !important;
  }

  .pl-90-md {
    padding-left: 5.625rem !important;
  }

  .m-95-md {
    margin: 5.9375rem;
  }

  .p-95-md {
    padding: 5.9375rem;
  }

  .mt-95-md {
    margin-top: 5.9375rem !important;
  }

  .pt-95-md {
    padding-top: 5.9375rem !important;
  }

  .mr-95-md {
    margin-right: 5.9375rem !important;
  }

  .pr-95-md {
    padding-right: 5.9375rem !important;
  }

  .mb-95-md {
    margin-bottom: 5.9375rem !important;
  }

  .pb-95-md {
    padding-bottom: 5.9375rem !important;
  }

  .ml-95-md {
    margin-left: 5.9375rem !important;
  }

  .pl-95-md {
    padding-left: 5.9375rem !important;
  }

  .m-100-md {
    margin: 6.25rem;
  }

  .p-100-md {
    padding: 6.25rem;
  }

  .mt-100-md {
    margin-top: 6.25rem !important;
  }

  .pt-100-md {
    padding-top: 6.25rem !important;
  }

  .mr-100-md {
    margin-right: 6.25rem !important;
  }

  .pr-100-md {
    padding-right: 6.25rem !important;
  }

  .mb-100-md {
    margin-bottom: 6.25rem !important;
  }

  .pb-100-md {
    padding-bottom: 6.25rem !important;
  }

  .ml-100-md {
    margin-left: 6.25rem !important;
  }

  .pl-100-md {
    padding-left: 6.25rem !important;
  }
}
@media (max-width: 992px) {
  .m-0-lg {
    margin: 0rem;
  }

  .p-0-lg {
    padding: 0rem;
  }

  .mt-0-lg {
    margin-top: 0rem !important;
  }

  .pt-0-lg {
    padding-top: 0rem !important;
  }

  .mr-0-lg {
    margin-right: 0rem !important;
  }

  .pr-0-lg {
    padding-right: 0rem !important;
  }

  .mb-0-lg {
    margin-bottom: 0rem !important;
  }

  .pb-0-lg {
    padding-bottom: 0rem !important;
  }

  .ml-0-lg {
    margin-left: 0rem !important;
  }

  .pl-0-lg {
    padding-left: 0rem !important;
  }

  .m-5-lg {
    margin: 0.3125rem;
  }

  .p-5-lg {
    padding: 0.3125rem;
  }

  .mt-5-lg {
    margin-top: 0.3125rem !important;
  }

  .pt-5-lg {
    padding-top: 0.3125rem !important;
  }

  .mr-5-lg {
    margin-right: 0.3125rem !important;
  }

  .pr-5-lg {
    padding-right: 0.3125rem !important;
  }

  .mb-5-lg {
    margin-bottom: 0.3125rem !important;
  }

  .pb-5-lg {
    padding-bottom: 0.3125rem !important;
  }

  .ml-5-lg {
    margin-left: 0.3125rem !important;
  }

  .pl-5-lg {
    padding-left: 0.3125rem !important;
  }

  .m-10-lg {
    margin: 0.625rem;
  }

  .p-10-lg {
    padding: 0.625rem;
  }

  .mt-10-lg {
    margin-top: 0.625rem !important;
  }

  .pt-10-lg {
    padding-top: 0.625rem !important;
  }

  .mr-10-lg {
    margin-right: 0.625rem !important;
  }

  .pr-10-lg {
    padding-right: 0.625rem !important;
  }

  .mb-10-lg {
    margin-bottom: 0.625rem !important;
  }

  .pb-10-lg {
    padding-bottom: 0.625rem !important;
  }

  .ml-10-lg {
    margin-left: 0.625rem !important;
  }

  .pl-10-lg {
    padding-left: 0.625rem !important;
  }

  .m-15-lg {
    margin: 0.9375rem;
  }

  .p-15-lg {
    padding: 0.9375rem;
  }

  .mt-15-lg {
    margin-top: 0.9375rem !important;
  }

  .pt-15-lg {
    padding-top: 0.9375rem !important;
  }

  .mr-15-lg {
    margin-right: 0.9375rem !important;
  }

  .pr-15-lg {
    padding-right: 0.9375rem !important;
  }

  .mb-15-lg {
    margin-bottom: 0.9375rem !important;
  }

  .pb-15-lg {
    padding-bottom: 0.9375rem !important;
  }

  .ml-15-lg {
    margin-left: 0.9375rem !important;
  }

  .pl-15-lg {
    padding-left: 0.9375rem !important;
  }

  .m-20-lg {
    margin: 1.25rem;
  }

  .p-20-lg {
    padding: 1.25rem;
  }

  .mt-20-lg {
    margin-top: 1.25rem !important;
  }

  .pt-20-lg {
    padding-top: 1.25rem !important;
  }

  .mr-20-lg {
    margin-right: 1.25rem !important;
  }

  .pr-20-lg {
    padding-right: 1.25rem !important;
  }

  .mb-20-lg {
    margin-bottom: 1.25rem !important;
  }

  .pb-20-lg {
    padding-bottom: 1.25rem !important;
  }

  .ml-20-lg {
    margin-left: 1.25rem !important;
  }

  .pl-20-lg {
    padding-left: 1.25rem !important;
  }

  .m-25-lg {
    margin: 1.5625rem;
  }

  .p-25-lg {
    padding: 1.5625rem;
  }

  .mt-25-lg {
    margin-top: 1.5625rem !important;
  }

  .pt-25-lg {
    padding-top: 1.5625rem !important;
  }

  .mr-25-lg {
    margin-right: 1.5625rem !important;
  }

  .pr-25-lg {
    padding-right: 1.5625rem !important;
  }

  .mb-25-lg {
    margin-bottom: 1.5625rem !important;
  }

  .pb-25-lg {
    padding-bottom: 1.5625rem !important;
  }

  .ml-25-lg {
    margin-left: 1.5625rem !important;
  }

  .pl-25-lg {
    padding-left: 1.5625rem !important;
  }

  .m-30-lg {
    margin: 1.875rem;
  }

  .p-30-lg {
    padding: 1.875rem;
  }

  .mt-30-lg {
    margin-top: 1.875rem !important;
  }

  .pt-30-lg {
    padding-top: 1.875rem !important;
  }

  .mr-30-lg {
    margin-right: 1.875rem !important;
  }

  .pr-30-lg {
    padding-right: 1.875rem !important;
  }

  .mb-30-lg {
    margin-bottom: 1.875rem !important;
  }

  .pb-30-lg {
    padding-bottom: 1.875rem !important;
  }

  .ml-30-lg {
    margin-left: 1.875rem !important;
  }

  .pl-30-lg {
    padding-left: 1.875rem !important;
  }

  .m-35-lg {
    margin: 2.1875rem;
  }

  .p-35-lg {
    padding: 2.1875rem;
  }

  .mt-35-lg {
    margin-top: 2.1875rem !important;
  }

  .pt-35-lg {
    padding-top: 2.1875rem !important;
  }

  .mr-35-lg {
    margin-right: 2.1875rem !important;
  }

  .pr-35-lg {
    padding-right: 2.1875rem !important;
  }

  .mb-35-lg {
    margin-bottom: 2.1875rem !important;
  }

  .pb-35-lg {
    padding-bottom: 2.1875rem !important;
  }

  .ml-35-lg {
    margin-left: 2.1875rem !important;
  }

  .pl-35-lg {
    padding-left: 2.1875rem !important;
  }

  .m-40-lg {
    margin: 2.5rem;
  }

  .p-40-lg {
    padding: 2.5rem;
  }

  .mt-40-lg {
    margin-top: 2.5rem !important;
  }

  .pt-40-lg {
    padding-top: 2.5rem !important;
  }

  .mr-40-lg {
    margin-right: 2.5rem !important;
  }

  .pr-40-lg {
    padding-right: 2.5rem !important;
  }

  .mb-40-lg {
    margin-bottom: 2.5rem !important;
  }

  .pb-40-lg {
    padding-bottom: 2.5rem !important;
  }

  .ml-40-lg {
    margin-left: 2.5rem !important;
  }

  .pl-40-lg {
    padding-left: 2.5rem !important;
  }

  .m-45-lg {
    margin: 2.8125rem;
  }

  .p-45-lg {
    padding: 2.8125rem;
  }

  .mt-45-lg {
    margin-top: 2.8125rem !important;
  }

  .pt-45-lg {
    padding-top: 2.8125rem !important;
  }

  .mr-45-lg {
    margin-right: 2.8125rem !important;
  }

  .pr-45-lg {
    padding-right: 2.8125rem !important;
  }

  .mb-45-lg {
    margin-bottom: 2.8125rem !important;
  }

  .pb-45-lg {
    padding-bottom: 2.8125rem !important;
  }

  .ml-45-lg {
    margin-left: 2.8125rem !important;
  }

  .pl-45-lg {
    padding-left: 2.8125rem !important;
  }

  .m-50-lg {
    margin: 3.125rem;
  }

  .p-50-lg {
    padding: 3.125rem;
  }

  .mt-50-lg {
    margin-top: 3.125rem !important;
  }

  .pt-50-lg {
    padding-top: 3.125rem !important;
  }

  .mr-50-lg {
    margin-right: 3.125rem !important;
  }

  .pr-50-lg {
    padding-right: 3.125rem !important;
  }

  .mb-50-lg {
    margin-bottom: 3.125rem !important;
  }

  .pb-50-lg {
    padding-bottom: 3.125rem !important;
  }

  .ml-50-lg {
    margin-left: 3.125rem !important;
  }

  .pl-50-lg {
    padding-left: 3.125rem !important;
  }

  .m-55-lg {
    margin: 3.4375rem;
  }

  .p-55-lg {
    padding: 3.4375rem;
  }

  .mt-55-lg {
    margin-top: 3.4375rem !important;
  }

  .pt-55-lg {
    padding-top: 3.4375rem !important;
  }

  .mr-55-lg {
    margin-right: 3.4375rem !important;
  }

  .pr-55-lg {
    padding-right: 3.4375rem !important;
  }

  .mb-55-lg {
    margin-bottom: 3.4375rem !important;
  }

  .pb-55-lg {
    padding-bottom: 3.4375rem !important;
  }

  .ml-55-lg {
    margin-left: 3.4375rem !important;
  }

  .pl-55-lg {
    padding-left: 3.4375rem !important;
  }

  .m-60-lg {
    margin: 3.75rem;
  }

  .p-60-lg {
    padding: 3.75rem;
  }

  .mt-60-lg {
    margin-top: 3.75rem !important;
  }

  .pt-60-lg {
    padding-top: 3.75rem !important;
  }

  .mr-60-lg {
    margin-right: 3.75rem !important;
  }

  .pr-60-lg {
    padding-right: 3.75rem !important;
  }

  .mb-60-lg {
    margin-bottom: 3.75rem !important;
  }

  .pb-60-lg {
    padding-bottom: 3.75rem !important;
  }

  .ml-60-lg {
    margin-left: 3.75rem !important;
  }

  .pl-60-lg {
    padding-left: 3.75rem !important;
  }

  .m-65-lg {
    margin: 4.0625rem;
  }

  .p-65-lg {
    padding: 4.0625rem;
  }

  .mt-65-lg {
    margin-top: 4.0625rem !important;
  }

  .pt-65-lg {
    padding-top: 4.0625rem !important;
  }

  .mr-65-lg {
    margin-right: 4.0625rem !important;
  }

  .pr-65-lg {
    padding-right: 4.0625rem !important;
  }

  .mb-65-lg {
    margin-bottom: 4.0625rem !important;
  }

  .pb-65-lg {
    padding-bottom: 4.0625rem !important;
  }

  .ml-65-lg {
    margin-left: 4.0625rem !important;
  }

  .pl-65-lg {
    padding-left: 4.0625rem !important;
  }

  .m-70-lg {
    margin: 4.375rem;
  }

  .p-70-lg {
    padding: 4.375rem;
  }

  .mt-70-lg {
    margin-top: 4.375rem !important;
  }

  .pt-70-lg {
    padding-top: 4.375rem !important;
  }

  .mr-70-lg {
    margin-right: 4.375rem !important;
  }

  .pr-70-lg {
    padding-right: 4.375rem !important;
  }

  .mb-70-lg {
    margin-bottom: 4.375rem !important;
  }

  .pb-70-lg {
    padding-bottom: 4.375rem !important;
  }

  .ml-70-lg {
    margin-left: 4.375rem !important;
  }

  .pl-70-lg {
    padding-left: 4.375rem !important;
  }

  .m-75-lg {
    margin: 4.6875rem;
  }

  .p-75-lg {
    padding: 4.6875rem;
  }

  .mt-75-lg {
    margin-top: 4.6875rem !important;
  }

  .pt-75-lg {
    padding-top: 4.6875rem !important;
  }

  .mr-75-lg {
    margin-right: 4.6875rem !important;
  }

  .pr-75-lg {
    padding-right: 4.6875rem !important;
  }

  .mb-75-lg {
    margin-bottom: 4.6875rem !important;
  }

  .pb-75-lg {
    padding-bottom: 4.6875rem !important;
  }

  .ml-75-lg {
    margin-left: 4.6875rem !important;
  }

  .pl-75-lg {
    padding-left: 4.6875rem !important;
  }

  .m-80-lg {
    margin: 5rem;
  }

  .p-80-lg {
    padding: 5rem;
  }

  .mt-80-lg {
    margin-top: 5rem !important;
  }

  .pt-80-lg {
    padding-top: 5rem !important;
  }

  .mr-80-lg {
    margin-right: 5rem !important;
  }

  .pr-80-lg {
    padding-right: 5rem !important;
  }

  .mb-80-lg {
    margin-bottom: 5rem !important;
  }

  .pb-80-lg {
    padding-bottom: 5rem !important;
  }

  .ml-80-lg {
    margin-left: 5rem !important;
  }

  .pl-80-lg {
    padding-left: 5rem !important;
  }

  .m-85-lg {
    margin: 5.3125rem;
  }

  .p-85-lg {
    padding: 5.3125rem;
  }

  .mt-85-lg {
    margin-top: 5.3125rem !important;
  }

  .pt-85-lg {
    padding-top: 5.3125rem !important;
  }

  .mr-85-lg {
    margin-right: 5.3125rem !important;
  }

  .pr-85-lg {
    padding-right: 5.3125rem !important;
  }

  .mb-85-lg {
    margin-bottom: 5.3125rem !important;
  }

  .pb-85-lg {
    padding-bottom: 5.3125rem !important;
  }

  .ml-85-lg {
    margin-left: 5.3125rem !important;
  }

  .pl-85-lg {
    padding-left: 5.3125rem !important;
  }

  .m-90-lg {
    margin: 5.625rem;
  }

  .p-90-lg {
    padding: 5.625rem;
  }

  .mt-90-lg {
    margin-top: 5.625rem !important;
  }

  .pt-90-lg {
    padding-top: 5.625rem !important;
  }

  .mr-90-lg {
    margin-right: 5.625rem !important;
  }

  .pr-90-lg {
    padding-right: 5.625rem !important;
  }

  .mb-90-lg {
    margin-bottom: 5.625rem !important;
  }

  .pb-90-lg {
    padding-bottom: 5.625rem !important;
  }

  .ml-90-lg {
    margin-left: 5.625rem !important;
  }

  .pl-90-lg {
    padding-left: 5.625rem !important;
  }

  .m-95-lg {
    margin: 5.9375rem;
  }

  .p-95-lg {
    padding: 5.9375rem;
  }

  .mt-95-lg {
    margin-top: 5.9375rem !important;
  }

  .pt-95-lg {
    padding-top: 5.9375rem !important;
  }

  .mr-95-lg {
    margin-right: 5.9375rem !important;
  }

  .pr-95-lg {
    padding-right: 5.9375rem !important;
  }

  .mb-95-lg {
    margin-bottom: 5.9375rem !important;
  }

  .pb-95-lg {
    padding-bottom: 5.9375rem !important;
  }

  .ml-95-lg {
    margin-left: 5.9375rem !important;
  }

  .pl-95-lg {
    padding-left: 5.9375rem !important;
  }

  .m-100-lg {
    margin: 6.25rem;
  }

  .p-100-lg {
    padding: 6.25rem;
  }

  .mt-100-lg {
    margin-top: 6.25rem !important;
  }

  .pt-100-lg {
    padding-top: 6.25rem !important;
  }

  .mr-100-lg {
    margin-right: 6.25rem !important;
  }

  .pr-100-lg {
    padding-right: 6.25rem !important;
  }

  .mb-100-lg {
    margin-bottom: 6.25rem !important;
  }

  .pb-100-lg {
    padding-bottom: 6.25rem !important;
  }

  .ml-100-lg {
    margin-left: 6.25rem !important;
  }

  .pl-100-lg {
    padding-left: 6.25rem !important;
  }
}
@media (max-width: 1200px) {
  .m-0-xl {
    margin: 0rem;
  }

  .p-0-xl {
    padding: 0rem;
  }

  .mt-0-xl {
    margin-top: 0rem !important;
  }

  .pt-0-xl {
    padding-top: 0rem !important;
  }

  .mr-0-xl {
    margin-right: 0rem !important;
  }

  .pr-0-xl {
    padding-right: 0rem !important;
  }

  .mb-0-xl {
    margin-bottom: 0rem !important;
  }

  .pb-0-xl {
    padding-bottom: 0rem !important;
  }

  .ml-0-xl {
    margin-left: 0rem !important;
  }

  .pl-0-xl {
    padding-left: 0rem !important;
  }

  .m-5-xl {
    margin: 0.3125rem;
  }

  .p-5-xl {
    padding: 0.3125rem;
  }

  .mt-5-xl {
    margin-top: 0.3125rem !important;
  }

  .pt-5-xl {
    padding-top: 0.3125rem !important;
  }

  .mr-5-xl {
    margin-right: 0.3125rem !important;
  }

  .pr-5-xl {
    padding-right: 0.3125rem !important;
  }

  .mb-5-xl {
    margin-bottom: 0.3125rem !important;
  }

  .pb-5-xl {
    padding-bottom: 0.3125rem !important;
  }

  .ml-5-xl {
    margin-left: 0.3125rem !important;
  }

  .pl-5-xl {
    padding-left: 0.3125rem !important;
  }

  .m-10-xl {
    margin: 0.625rem;
  }

  .p-10-xl {
    padding: 0.625rem;
  }

  .mt-10-xl {
    margin-top: 0.625rem !important;
  }

  .pt-10-xl {
    padding-top: 0.625rem !important;
  }

  .mr-10-xl {
    margin-right: 0.625rem !important;
  }

  .pr-10-xl {
    padding-right: 0.625rem !important;
  }

  .mb-10-xl {
    margin-bottom: 0.625rem !important;
  }

  .pb-10-xl {
    padding-bottom: 0.625rem !important;
  }

  .ml-10-xl {
    margin-left: 0.625rem !important;
  }

  .pl-10-xl {
    padding-left: 0.625rem !important;
  }

  .m-15-xl {
    margin: 0.9375rem;
  }

  .p-15-xl {
    padding: 0.9375rem;
  }

  .mt-15-xl {
    margin-top: 0.9375rem !important;
  }

  .pt-15-xl {
    padding-top: 0.9375rem !important;
  }

  .mr-15-xl {
    margin-right: 0.9375rem !important;
  }

  .pr-15-xl {
    padding-right: 0.9375rem !important;
  }

  .mb-15-xl {
    margin-bottom: 0.9375rem !important;
  }

  .pb-15-xl {
    padding-bottom: 0.9375rem !important;
  }

  .ml-15-xl {
    margin-left: 0.9375rem !important;
  }

  .pl-15-xl {
    padding-left: 0.9375rem !important;
  }

  .m-20-xl {
    margin: 1.25rem;
  }

  .p-20-xl {
    padding: 1.25rem;
  }

  .mt-20-xl {
    margin-top: 1.25rem !important;
  }

  .pt-20-xl {
    padding-top: 1.25rem !important;
  }

  .mr-20-xl {
    margin-right: 1.25rem !important;
  }

  .pr-20-xl {
    padding-right: 1.25rem !important;
  }

  .mb-20-xl {
    margin-bottom: 1.25rem !important;
  }

  .pb-20-xl {
    padding-bottom: 1.25rem !important;
  }

  .ml-20-xl {
    margin-left: 1.25rem !important;
  }

  .pl-20-xl {
    padding-left: 1.25rem !important;
  }

  .m-25-xl {
    margin: 1.5625rem;
  }

  .p-25-xl {
    padding: 1.5625rem;
  }

  .mt-25-xl {
    margin-top: 1.5625rem !important;
  }

  .pt-25-xl {
    padding-top: 1.5625rem !important;
  }

  .mr-25-xl {
    margin-right: 1.5625rem !important;
  }

  .pr-25-xl {
    padding-right: 1.5625rem !important;
  }

  .mb-25-xl {
    margin-bottom: 1.5625rem !important;
  }

  .pb-25-xl {
    padding-bottom: 1.5625rem !important;
  }

  .ml-25-xl {
    margin-left: 1.5625rem !important;
  }

  .pl-25-xl {
    padding-left: 1.5625rem !important;
  }

  .m-30-xl {
    margin: 1.875rem;
  }

  .p-30-xl {
    padding: 1.875rem;
  }

  .mt-30-xl {
    margin-top: 1.875rem !important;
  }

  .pt-30-xl {
    padding-top: 1.875rem !important;
  }

  .mr-30-xl {
    margin-right: 1.875rem !important;
  }

  .pr-30-xl {
    padding-right: 1.875rem !important;
  }

  .mb-30-xl {
    margin-bottom: 1.875rem !important;
  }

  .pb-30-xl {
    padding-bottom: 1.875rem !important;
  }

  .ml-30-xl {
    margin-left: 1.875rem !important;
  }

  .pl-30-xl {
    padding-left: 1.875rem !important;
  }

  .m-35-xl {
    margin: 2.1875rem;
  }

  .p-35-xl {
    padding: 2.1875rem;
  }

  .mt-35-xl {
    margin-top: 2.1875rem !important;
  }

  .pt-35-xl {
    padding-top: 2.1875rem !important;
  }

  .mr-35-xl {
    margin-right: 2.1875rem !important;
  }

  .pr-35-xl {
    padding-right: 2.1875rem !important;
  }

  .mb-35-xl {
    margin-bottom: 2.1875rem !important;
  }

  .pb-35-xl {
    padding-bottom: 2.1875rem !important;
  }

  .ml-35-xl {
    margin-left: 2.1875rem !important;
  }

  .pl-35-xl {
    padding-left: 2.1875rem !important;
  }

  .m-40-xl {
    margin: 2.5rem;
  }

  .p-40-xl {
    padding: 2.5rem;
  }

  .mt-40-xl {
    margin-top: 2.5rem !important;
  }

  .pt-40-xl {
    padding-top: 2.5rem !important;
  }

  .mr-40-xl {
    margin-right: 2.5rem !important;
  }

  .pr-40-xl {
    padding-right: 2.5rem !important;
  }

  .mb-40-xl {
    margin-bottom: 2.5rem !important;
  }

  .pb-40-xl {
    padding-bottom: 2.5rem !important;
  }

  .ml-40-xl {
    margin-left: 2.5rem !important;
  }

  .pl-40-xl {
    padding-left: 2.5rem !important;
  }

  .m-45-xl {
    margin: 2.8125rem;
  }

  .p-45-xl {
    padding: 2.8125rem;
  }

  .mt-45-xl {
    margin-top: 2.8125rem !important;
  }

  .pt-45-xl {
    padding-top: 2.8125rem !important;
  }

  .mr-45-xl {
    margin-right: 2.8125rem !important;
  }

  .pr-45-xl {
    padding-right: 2.8125rem !important;
  }

  .mb-45-xl {
    margin-bottom: 2.8125rem !important;
  }

  .pb-45-xl {
    padding-bottom: 2.8125rem !important;
  }

  .ml-45-xl {
    margin-left: 2.8125rem !important;
  }

  .pl-45-xl {
    padding-left: 2.8125rem !important;
  }

  .m-50-xl {
    margin: 3.125rem;
  }

  .p-50-xl {
    padding: 3.125rem;
  }

  .mt-50-xl {
    margin-top: 3.125rem !important;
  }

  .pt-50-xl {
    padding-top: 3.125rem !important;
  }

  .mr-50-xl {
    margin-right: 3.125rem !important;
  }

  .pr-50-xl {
    padding-right: 3.125rem !important;
  }

  .mb-50-xl {
    margin-bottom: 3.125rem !important;
  }

  .pb-50-xl {
    padding-bottom: 3.125rem !important;
  }

  .ml-50-xl {
    margin-left: 3.125rem !important;
  }

  .pl-50-xl {
    padding-left: 3.125rem !important;
  }

  .m-55-xl {
    margin: 3.4375rem;
  }

  .p-55-xl {
    padding: 3.4375rem;
  }

  .mt-55-xl {
    margin-top: 3.4375rem !important;
  }

  .pt-55-xl {
    padding-top: 3.4375rem !important;
  }

  .mr-55-xl {
    margin-right: 3.4375rem !important;
  }

  .pr-55-xl {
    padding-right: 3.4375rem !important;
  }

  .mb-55-xl {
    margin-bottom: 3.4375rem !important;
  }

  .pb-55-xl {
    padding-bottom: 3.4375rem !important;
  }

  .ml-55-xl {
    margin-left: 3.4375rem !important;
  }

  .pl-55-xl {
    padding-left: 3.4375rem !important;
  }

  .m-60-xl {
    margin: 3.75rem;
  }

  .p-60-xl {
    padding: 3.75rem;
  }

  .mt-60-xl {
    margin-top: 3.75rem !important;
  }

  .pt-60-xl {
    padding-top: 3.75rem !important;
  }

  .mr-60-xl {
    margin-right: 3.75rem !important;
  }

  .pr-60-xl {
    padding-right: 3.75rem !important;
  }

  .mb-60-xl {
    margin-bottom: 3.75rem !important;
  }

  .pb-60-xl {
    padding-bottom: 3.75rem !important;
  }

  .ml-60-xl {
    margin-left: 3.75rem !important;
  }

  .pl-60-xl {
    padding-left: 3.75rem !important;
  }

  .m-65-xl {
    margin: 4.0625rem;
  }

  .p-65-xl {
    padding: 4.0625rem;
  }

  .mt-65-xl {
    margin-top: 4.0625rem !important;
  }

  .pt-65-xl {
    padding-top: 4.0625rem !important;
  }

  .mr-65-xl {
    margin-right: 4.0625rem !important;
  }

  .pr-65-xl {
    padding-right: 4.0625rem !important;
  }

  .mb-65-xl {
    margin-bottom: 4.0625rem !important;
  }

  .pb-65-xl {
    padding-bottom: 4.0625rem !important;
  }

  .ml-65-xl {
    margin-left: 4.0625rem !important;
  }

  .pl-65-xl {
    padding-left: 4.0625rem !important;
  }

  .m-70-xl {
    margin: 4.375rem;
  }

  .p-70-xl {
    padding: 4.375rem;
  }

  .mt-70-xl {
    margin-top: 4.375rem !important;
  }

  .pt-70-xl {
    padding-top: 4.375rem !important;
  }

  .mr-70-xl {
    margin-right: 4.375rem !important;
  }

  .pr-70-xl {
    padding-right: 4.375rem !important;
  }

  .mb-70-xl {
    margin-bottom: 4.375rem !important;
  }

  .pb-70-xl {
    padding-bottom: 4.375rem !important;
  }

  .ml-70-xl {
    margin-left: 4.375rem !important;
  }

  .pl-70-xl {
    padding-left: 4.375rem !important;
  }

  .m-75-xl {
    margin: 4.6875rem;
  }

  .p-75-xl {
    padding: 4.6875rem;
  }

  .mt-75-xl {
    margin-top: 4.6875rem !important;
  }

  .pt-75-xl {
    padding-top: 4.6875rem !important;
  }

  .mr-75-xl {
    margin-right: 4.6875rem !important;
  }

  .pr-75-xl {
    padding-right: 4.6875rem !important;
  }

  .mb-75-xl {
    margin-bottom: 4.6875rem !important;
  }

  .pb-75-xl {
    padding-bottom: 4.6875rem !important;
  }

  .ml-75-xl {
    margin-left: 4.6875rem !important;
  }

  .pl-75-xl {
    padding-left: 4.6875rem !important;
  }

  .m-80-xl {
    margin: 5rem;
  }

  .p-80-xl {
    padding: 5rem;
  }

  .mt-80-xl {
    margin-top: 5rem !important;
  }

  .pt-80-xl {
    padding-top: 5rem !important;
  }

  .mr-80-xl {
    margin-right: 5rem !important;
  }

  .pr-80-xl {
    padding-right: 5rem !important;
  }

  .mb-80-xl {
    margin-bottom: 5rem !important;
  }

  .pb-80-xl {
    padding-bottom: 5rem !important;
  }

  .ml-80-xl {
    margin-left: 5rem !important;
  }

  .pl-80-xl {
    padding-left: 5rem !important;
  }

  .m-85-xl {
    margin: 5.3125rem;
  }

  .p-85-xl {
    padding: 5.3125rem;
  }

  .mt-85-xl {
    margin-top: 5.3125rem !important;
  }

  .pt-85-xl {
    padding-top: 5.3125rem !important;
  }

  .mr-85-xl {
    margin-right: 5.3125rem !important;
  }

  .pr-85-xl {
    padding-right: 5.3125rem !important;
  }

  .mb-85-xl {
    margin-bottom: 5.3125rem !important;
  }

  .pb-85-xl {
    padding-bottom: 5.3125rem !important;
  }

  .ml-85-xl {
    margin-left: 5.3125rem !important;
  }

  .pl-85-xl {
    padding-left: 5.3125rem !important;
  }

  .m-90-xl {
    margin: 5.625rem;
  }

  .p-90-xl {
    padding: 5.625rem;
  }

  .mt-90-xl {
    margin-top: 5.625rem !important;
  }

  .pt-90-xl {
    padding-top: 5.625rem !important;
  }

  .mr-90-xl {
    margin-right: 5.625rem !important;
  }

  .pr-90-xl {
    padding-right: 5.625rem !important;
  }

  .mb-90-xl {
    margin-bottom: 5.625rem !important;
  }

  .pb-90-xl {
    padding-bottom: 5.625rem !important;
  }

  .ml-90-xl {
    margin-left: 5.625rem !important;
  }

  .pl-90-xl {
    padding-left: 5.625rem !important;
  }

  .m-95-xl {
    margin: 5.9375rem;
  }

  .p-95-xl {
    padding: 5.9375rem;
  }

  .mt-95-xl {
    margin-top: 5.9375rem !important;
  }

  .pt-95-xl {
    padding-top: 5.9375rem !important;
  }

  .mr-95-xl {
    margin-right: 5.9375rem !important;
  }

  .pr-95-xl {
    padding-right: 5.9375rem !important;
  }

  .mb-95-xl {
    margin-bottom: 5.9375rem !important;
  }

  .pb-95-xl {
    padding-bottom: 5.9375rem !important;
  }

  .ml-95-xl {
    margin-left: 5.9375rem !important;
  }

  .pl-95-xl {
    padding-left: 5.9375rem !important;
  }

  .m-100-xl {
    margin: 6.25rem;
  }

  .p-100-xl {
    padding: 6.25rem;
  }

  .mt-100-xl {
    margin-top: 6.25rem !important;
  }

  .pt-100-xl {
    padding-top: 6.25rem !important;
  }

  .mr-100-xl {
    margin-right: 6.25rem !important;
  }

  .pr-100-xl {
    padding-right: 6.25rem !important;
  }

  .mb-100-xl {
    margin-bottom: 6.25rem !important;
  }

  .pb-100-xl {
    padding-bottom: 6.25rem !important;
  }

  .ml-100-xl {
    margin-left: 6.25rem !important;
  }

  .pl-100-xl {
    padding-left: 6.25rem !important;
  }
}
/*-----------------------------------------------------------------------------
    $ Display

    References:
    https://github.com/malothnaresh/css-utilities/blob/master/common.scss
    https://medium.com/@jerrythimothy/margins-paddings-sass-mixin-16460c32507f
-----------------------------------------------------------------------------*/
.flex-direction-row {
  flex-direction: row;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse;
}

.flex-direction-column {
  flex-direction: column;
}

.flex-direction-column-reverse {
  flex-direction: column-reverse;
}

.flex-wrap-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-wrap {
  flex-wrap: wrap;
}

.flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-flex-start {
  justify-content: flex-start;
}

.justify-content-flex-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-end {
  justify-content: end;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-flex-start {
  align-items: flex-start;
}

.align-items-flex-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-start {
  align-items: start;
}

.align-items-end {
  align-items: end;
}

.align-content-flex-start {
  align-content: flex-start;
}

.align-content-flex-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-space-between {
  align-content: space-between;
}

.align-content-space-around {
  align-content: space-around;
}

.align-content-space-evenly {
  align-content: space-evenly;
}

.align-content-stretch {
  align-content: stretch;
}

.align-content-start {
  align-content: start;
}

.align-content-end {
  align-content: end;
}

.align-content-baseline {
  align-content: baseline;
}

@media (max-width: 576px) {
  .flex-direction-row-xs {
    flex-direction: row;
  }

  .flex-direction-row-reverse-xs {
    flex-direction: row-reverse;
  }

  .flex-direction-column-xs {
    flex-direction: column;
  }

  .flex-direction-column-reverse-xs {
    flex-direction: column-reverse;
  }

  .flex-wrap-nowrap-xs {
    flex-wrap: nowrap;
  }

  .flex-wrap-wrap-xs {
    flex-wrap: wrap;
  }

  .flex-wrap-wrap-reverse-xs {
    flex-wrap: wrap-reverse;
  }

  .justify-content-flex-start-xs {
    justify-content: flex-start;
  }

  .justify-content-flex-end-xs {
    justify-content: flex-end;
  }

  .justify-content-center-xs {
    justify-content: center;
  }

  .justify-content-space-between-xs {
    justify-content: space-between;
  }

  .justify-content-space-around-xs {
    justify-content: space-around;
  }

  .justify-content-space-evenly-xs {
    justify-content: space-evenly;
  }

  .justify-content-start-xs {
    justify-content: start;
  }

  .justify-content-end-xs {
    justify-content: end;
  }

  .align-items-stretch-xs {
    align-items: stretch;
  }

  .align-items-flex-start-xs {
    align-items: flex-start;
  }

  .align-items-flex-end-xs {
    align-items: flex-end;
  }

  .align-items-center-xs {
    align-items: center;
  }

  .align-items-baseline-xs {
    align-items: baseline;
  }

  .align-items-start-xs {
    align-items: start;
  }

  .align-items-end-xs {
    align-items: end;
  }

  .align-content-flex-start-xs {
    align-content: flex-start;
  }

  .align-content-flex-end-xs {
    align-content: flex-end;
  }

  .align-content-center-xs {
    align-content: center;
  }

  .align-content-space-between-xs {
    align-content: space-between;
  }

  .align-content-space-around-xs {
    align-content: space-around;
  }

  .align-content-space-evenly-xs {
    align-content: space-evenly;
  }

  .align-content-stretch-xs {
    align-content: stretch;
  }

  .align-content-start-xs {
    align-content: start;
  }

  .align-content-end-xs {
    align-content: end;
  }

  .align-content-baseline-xs {
    align-content: baseline;
  }
}
@media (max-width: 640px) {
  .flex-direction-row-sm {
    flex-direction: row;
  }

  .flex-direction-row-reverse-sm {
    flex-direction: row-reverse;
  }

  .flex-direction-column-sm {
    flex-direction: column;
  }

  .flex-direction-column-reverse-sm {
    flex-direction: column-reverse;
  }

  .flex-wrap-nowrap-sm {
    flex-wrap: nowrap;
  }

  .flex-wrap-wrap-sm {
    flex-wrap: wrap;
  }

  .flex-wrap-wrap-reverse-sm {
    flex-wrap: wrap-reverse;
  }

  .justify-content-flex-start-sm {
    justify-content: flex-start;
  }

  .justify-content-flex-end-sm {
    justify-content: flex-end;
  }

  .justify-content-center-sm {
    justify-content: center;
  }

  .justify-content-space-between-sm {
    justify-content: space-between;
  }

  .justify-content-space-around-sm {
    justify-content: space-around;
  }

  .justify-content-space-evenly-sm {
    justify-content: space-evenly;
  }

  .justify-content-start-sm {
    justify-content: start;
  }

  .justify-content-end-sm {
    justify-content: end;
  }

  .align-items-stretch-sm {
    align-items: stretch;
  }

  .align-items-flex-start-sm {
    align-items: flex-start;
  }

  .align-items-flex-end-sm {
    align-items: flex-end;
  }

  .align-items-center-sm {
    align-items: center;
  }

  .align-items-baseline-sm {
    align-items: baseline;
  }

  .align-items-start-sm {
    align-items: start;
  }

  .align-items-end-sm {
    align-items: end;
  }

  .align-content-flex-start-sm {
    align-content: flex-start;
  }

  .align-content-flex-end-sm {
    align-content: flex-end;
  }

  .align-content-center-sm {
    align-content: center;
  }

  .align-content-space-between-sm {
    align-content: space-between;
  }

  .align-content-space-around-sm {
    align-content: space-around;
  }

  .align-content-space-evenly-sm {
    align-content: space-evenly;
  }

  .align-content-stretch-sm {
    align-content: stretch;
  }

  .align-content-start-sm {
    align-content: start;
  }

  .align-content-end-sm {
    align-content: end;
  }

  .align-content-baseline-sm {
    align-content: baseline;
  }
}
@media (max-width: 768px) {
  .flex-direction-row-md {
    flex-direction: row;
  }

  .flex-direction-row-reverse-md {
    flex-direction: row-reverse;
  }

  .flex-direction-column-md {
    flex-direction: column;
  }

  .flex-direction-column-reverse-md {
    flex-direction: column-reverse;
  }

  .flex-wrap-nowrap-md {
    flex-wrap: nowrap;
  }

  .flex-wrap-wrap-md {
    flex-wrap: wrap;
  }

  .flex-wrap-wrap-reverse-md {
    flex-wrap: wrap-reverse;
  }

  .justify-content-flex-start-md {
    justify-content: flex-start;
  }

  .justify-content-flex-end-md {
    justify-content: flex-end;
  }

  .justify-content-center-md {
    justify-content: center;
  }

  .justify-content-space-between-md {
    justify-content: space-between;
  }

  .justify-content-space-around-md {
    justify-content: space-around;
  }

  .justify-content-space-evenly-md {
    justify-content: space-evenly;
  }

  .justify-content-start-md {
    justify-content: start;
  }

  .justify-content-end-md {
    justify-content: end;
  }

  .align-items-stretch-md {
    align-items: stretch;
  }

  .align-items-flex-start-md {
    align-items: flex-start;
  }

  .align-items-flex-end-md {
    align-items: flex-end;
  }

  .align-items-center-md {
    align-items: center;
  }

  .align-items-baseline-md {
    align-items: baseline;
  }

  .align-items-start-md {
    align-items: start;
  }

  .align-items-end-md {
    align-items: end;
  }

  .align-content-flex-start-md {
    align-content: flex-start;
  }

  .align-content-flex-end-md {
    align-content: flex-end;
  }

  .align-content-center-md {
    align-content: center;
  }

  .align-content-space-between-md {
    align-content: space-between;
  }

  .align-content-space-around-md {
    align-content: space-around;
  }

  .align-content-space-evenly-md {
    align-content: space-evenly;
  }

  .align-content-stretch-md {
    align-content: stretch;
  }

  .align-content-start-md {
    align-content: start;
  }

  .align-content-end-md {
    align-content: end;
  }

  .align-content-baseline-md {
    align-content: baseline;
  }
}
@media (max-width: 992px) {
  .flex-direction-row-lg {
    flex-direction: row;
  }

  .flex-direction-row-reverse-lg {
    flex-direction: row-reverse;
  }

  .flex-direction-column-lg {
    flex-direction: column;
  }

  .flex-direction-column-reverse-lg {
    flex-direction: column-reverse;
  }

  .flex-wrap-nowrap-lg {
    flex-wrap: nowrap;
  }

  .flex-wrap-wrap-lg {
    flex-wrap: wrap;
  }

  .flex-wrap-wrap-reverse-lg {
    flex-wrap: wrap-reverse;
  }

  .justify-content-flex-start-lg {
    justify-content: flex-start;
  }

  .justify-content-flex-end-lg {
    justify-content: flex-end;
  }

  .justify-content-center-lg {
    justify-content: center;
  }

  .justify-content-space-between-lg {
    justify-content: space-between;
  }

  .justify-content-space-around-lg {
    justify-content: space-around;
  }

  .justify-content-space-evenly-lg {
    justify-content: space-evenly;
  }

  .justify-content-start-lg {
    justify-content: start;
  }

  .justify-content-end-lg {
    justify-content: end;
  }

  .align-items-stretch-lg {
    align-items: stretch;
  }

  .align-items-flex-start-lg {
    align-items: flex-start;
  }

  .align-items-flex-end-lg {
    align-items: flex-end;
  }

  .align-items-center-lg {
    align-items: center;
  }

  .align-items-baseline-lg {
    align-items: baseline;
  }

  .align-items-start-lg {
    align-items: start;
  }

  .align-items-end-lg {
    align-items: end;
  }

  .align-content-flex-start-lg {
    align-content: flex-start;
  }

  .align-content-flex-end-lg {
    align-content: flex-end;
  }

  .align-content-center-lg {
    align-content: center;
  }

  .align-content-space-between-lg {
    align-content: space-between;
  }

  .align-content-space-around-lg {
    align-content: space-around;
  }

  .align-content-space-evenly-lg {
    align-content: space-evenly;
  }

  .align-content-stretch-lg {
    align-content: stretch;
  }

  .align-content-start-lg {
    align-content: start;
  }

  .align-content-end-lg {
    align-content: end;
  }

  .align-content-baseline-lg {
    align-content: baseline;
  }
}
@media (max-width: 1200px) {
  .flex-direction-row-xl {
    flex-direction: row;
  }

  .flex-direction-row-reverse-xl {
    flex-direction: row-reverse;
  }

  .flex-direction-column-xl {
    flex-direction: column;
  }

  .flex-direction-column-reverse-xl {
    flex-direction: column-reverse;
  }

  .flex-wrap-nowrap-xl {
    flex-wrap: nowrap;
  }

  .flex-wrap-wrap-xl {
    flex-wrap: wrap;
  }

  .flex-wrap-wrap-reverse-xl {
    flex-wrap: wrap-reverse;
  }

  .justify-content-flex-start-xl {
    justify-content: flex-start;
  }

  .justify-content-flex-end-xl {
    justify-content: flex-end;
  }

  .justify-content-center-xl {
    justify-content: center;
  }

  .justify-content-space-between-xl {
    justify-content: space-between;
  }

  .justify-content-space-around-xl {
    justify-content: space-around;
  }

  .justify-content-space-evenly-xl {
    justify-content: space-evenly;
  }

  .justify-content-start-xl {
    justify-content: start;
  }

  .justify-content-end-xl {
    justify-content: end;
  }

  .align-items-stretch-xl {
    align-items: stretch;
  }

  .align-items-flex-start-xl {
    align-items: flex-start;
  }

  .align-items-flex-end-xl {
    align-items: flex-end;
  }

  .align-items-center-xl {
    align-items: center;
  }

  .align-items-baseline-xl {
    align-items: baseline;
  }

  .align-items-start-xl {
    align-items: start;
  }

  .align-items-end-xl {
    align-items: end;
  }

  .align-content-flex-start-xl {
    align-content: flex-start;
  }

  .align-content-flex-end-xl {
    align-content: flex-end;
  }

  .align-content-center-xl {
    align-content: center;
  }

  .align-content-space-between-xl {
    align-content: space-between;
  }

  .align-content-space-around-xl {
    align-content: space-around;
  }

  .align-content-space-evenly-xl {
    align-content: space-evenly;
  }

  .align-content-stretch-xl {
    align-content: stretch;
  }

  .align-content-start-xl {
    align-content: start;
  }

  .align-content-end-xl {
    align-content: end;
  }

  .align-content-baseline-xl {
    align-content: baseline;
  }
}
.d-block {
  display: block;
}

.d-inline-block {
  display: inline-block;
}

.d-flex {
  display: flex;
}

.d-grid {
  display: grid;
}

.d-none {
  display: none;
}

@media (max-width: 576px) {
  .d-block-xs {
    display: block;
  }

  .d-inline-block-xs {
    display: inline-block;
  }

  .d-flex-xs {
    display: flex;
  }

  .d-grid-xs {
    display: grid;
  }

  .d-none-xs {
    display: none;
  }
}
@media (max-width: 640px) {
  .d-block-sm {
    display: block;
  }

  .d-inline-block-sm {
    display: inline-block;
  }

  .d-flex-sm {
    display: flex;
  }

  .d-grid-sm {
    display: grid;
  }

  .d-none-sm {
    display: none;
  }
}
@media (max-width: 768px) {
  .d-block-md {
    display: block;
  }

  .d-inline-block-md {
    display: inline-block;
  }

  .d-flex-md {
    display: flex;
  }

  .d-grid-md {
    display: grid;
  }

  .d-none-md {
    display: none;
  }
}
@media (max-width: 992px) {
  .d-block-lg {
    display: block;
  }

  .d-inline-block-lg {
    display: inline-block;
  }

  .d-flex-lg {
    display: flex;
  }

  .d-grid-lg {
    display: grid;
  }

  .d-none-lg {
    display: none;
  }
}
@media (max-width: 1200px) {
  .d-block-xl {
    display: block;
  }

  .d-inline-block-xl {
    display: inline-block;
  }

  .d-flex-xl {
    display: flex;
  }

  .d-grid-xl {
    display: grid;
  }

  .d-none-xl {
    display: none;
  }
}
/*-----------------------------------------------------------------------------
    $ Width

    References:
    https://github.com/malothnaresh/css-utilities/blob/master/common.scss
    https://medium.com/@jerrythimothy/margins-paddings-sass-mixin-16460c32507f
-----------------------------------------------------------------------------*/
.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33%;
}

.w-50 {
  width: 50%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

@media (max-width: 576px) {
  .w-5-xs {
    width: 5%;
  }

  .w-10-xs {
    width: 10%;
  }

  .w-15-xs {
    width: 15%;
  }

  .w-20-xs {
    width: 20%;
  }

  .w-25-xs {
    width: 25%;
  }

  .w-30-xs {
    width: 30%;
  }

  .w-33-xs {
    width: 33%;
  }

  .w-50-xs {
    width: 50%;
  }

  .w-70-xs {
    width: 70%;
  }

  .w-75-xs {
    width: 75%;
  }

  .w-80-xs {
    width: 80%;
  }

  .w-85-xs {
    width: 85%;
  }

  .w-90-xs {
    width: 90%;
  }

  .w-95-xs {
    width: 95%;
  }

  .w-100-xs {
    width: 100%;
  }

  .w-auto-xs {
    width: auto;
  }
}
@media (max-width: 640px) {
  .w-5-sm {
    width: 5%;
  }

  .w-10-sm {
    width: 10%;
  }

  .w-15-sm {
    width: 15%;
  }

  .w-20-sm {
    width: 20%;
  }

  .w-25-sm {
    width: 25%;
  }

  .w-30-sm {
    width: 30%;
  }

  .w-33-sm {
    width: 33%;
  }

  .w-50-sm {
    width: 50%;
  }

  .w-70-sm {
    width: 70%;
  }

  .w-75-sm {
    width: 75%;
  }

  .w-80-sm {
    width: 80%;
  }

  .w-85-sm {
    width: 85%;
  }

  .w-90-sm {
    width: 90%;
  }

  .w-95-sm {
    width: 95%;
  }

  .w-100-sm {
    width: 100%;
  }

  .w-auto-sm {
    width: auto;
  }
}
@media (max-width: 768px) {
  .w-5-md {
    width: 5%;
  }

  .w-10-md {
    width: 10%;
  }

  .w-15-md {
    width: 15%;
  }

  .w-20-md {
    width: 20%;
  }

  .w-25-md {
    width: 25%;
  }

  .w-30-md {
    width: 30%;
  }

  .w-33-md {
    width: 33%;
  }

  .w-50-md {
    width: 50%;
  }

  .w-70-md {
    width: 70%;
  }

  .w-75-md {
    width: 75%;
  }

  .w-80-md {
    width: 80%;
  }

  .w-85-md {
    width: 85%;
  }

  .w-90-md {
    width: 90%;
  }

  .w-95-md {
    width: 95%;
  }

  .w-100-md {
    width: 100%;
  }

  .w-auto-md {
    width: auto;
  }
}
@media (max-width: 992px) {
  .w-5-lg {
    width: 5%;
  }

  .w-10-lg {
    width: 10%;
  }

  .w-15-lg {
    width: 15%;
  }

  .w-20-lg {
    width: 20%;
  }

  .w-25-lg {
    width: 25%;
  }

  .w-30-lg {
    width: 30%;
  }

  .w-33-lg {
    width: 33%;
  }

  .w-50-lg {
    width: 50%;
  }

  .w-70-lg {
    width: 70%;
  }

  .w-75-lg {
    width: 75%;
  }

  .w-80-lg {
    width: 80%;
  }

  .w-85-lg {
    width: 85%;
  }

  .w-90-lg {
    width: 90%;
  }

  .w-95-lg {
    width: 95%;
  }

  .w-100-lg {
    width: 100%;
  }

  .w-auto-lg {
    width: auto;
  }
}
@media (max-width: 1200px) {
  .w-5-xl {
    width: 5%;
  }

  .w-10-xl {
    width: 10%;
  }

  .w-15-xl {
    width: 15%;
  }

  .w-20-xl {
    width: 20%;
  }

  .w-25-xl {
    width: 25%;
  }

  .w-30-xl {
    width: 30%;
  }

  .w-33-xl {
    width: 33%;
  }

  .w-50-xl {
    width: 50%;
  }

  .w-70-xl {
    width: 70%;
  }

  .w-75-xl {
    width: 75%;
  }

  .w-80-xl {
    width: 80%;
  }

  .w-85-xl {
    width: 85%;
  }

  .w-90-xl {
    width: 90%;
  }

  .w-95-xl {
    width: 95%;
  }

  .w-100-xl {
    width: 100%;
  }

  .w-auto-xl {
    width: auto;
  }
}
/*-----------------------------------------------------------------------------
    $ Typography
-----------------------------------------------------------------------------*/
h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

a,
a:visited,
a:active {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  border-style: none;
  display: block;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  color: var(--color-body-text);
}

h1 {
  font-size: 3rem;
}
@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }
}
@media (max-width: 640px) {
  h1 {
    font-size: 2rem;
  }
}

h2 {
  font-size: 2.25rem;
}
@media (max-width: 768px) {
  h2 {
    font-size: 2rem;
  }
}
@media (max-width: 640px) {
  h2 {
    font-size: 1.625rem;
  }
}

h3 {
  font-size: 1.5rem;
}
@media (max-width: 768px) {
  h3 {
    font-size: 1.5rem;
  }
}
@media (max-width: 640px) {
  h3 {
    font-size: 1.375rem;
  }
}

h4 {
  font-size: 1.125rem;
}

h5 {
  font-size: 1rem;
}

p {
  color: var(--color-body-text);
  line-height: 1.5;
  font-size: 1rem;
  margin: 0;
}

.text-black {
  color: var(--color-black);
}
.text-white {
  color: var(--color-heading-text);
}
.text-grey {
  color: var(--color-body-text);
}
.text-primary-blue {
  color: var(--color-primary-blue);
}
.text-navy-blue {
  color: var(--color-navy-blue);
}

.ff-primary {
  font-family: var(--font-horatio), Helvetica, sans-serif;
}
.ff-secondary {
  font-family: var(--font-roboto), Helvetica, sans-serif;
}

.display-heading-1 {
  font-size: 4.5rem;
  line-height: 1.25;
}
@media (max-width: 768px) {
  .display-heading-1 {
    font-size: 3.375rem;
  }
}
@media (max-width: 640px) {
  .display-heading-1 {
    font-size: 2.5rem;
  }
}

.text-subheading {
  font-size: 1rem;
  color: var(--color-subheading-text);
  margin-bottom: 0.3125rem;
  letter-spacing: 0.0625rem;
}
@media (max-width: 640px) {
  .text-subheading {
    font-size: 0.875rem;
  }
}

@media (max-width: 640px) {
  .text-paragraph {
    font-size: 0.875rem;
  }
}
.text-paragraph--mw-1 {
  max-width: 33.75rem;
}
.text-paragraph--mw-2-desktop {
  max-width: 30rem;
}
@media (max-width: 768px) {
  .text-paragraph--mw-2-desktop {
    max-width: unset;
  }
}

a {
  color: var(--color-primary-blue);
  cursor: pointer;
}
a.hyperlink:hover {
  text-decoration: underline;
}

/*-----------------------------------------------------------------------------
    $ Layout
-----------------------------------------------------------------------------*/
.container {
  padding: 0;
  max-width: 62.5rem;
  margin: 0 auto 2.875rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
@media (max-width: 1200px) {
  .container {
    padding: 0 1.5625rem;
    margin-left: 1.5625rem;
    margin-right: 1.5625rem;
  }
}
@media (max-width: 768px) {
  .container {
    padding: 0 1.5625rem;
  }
}
/*-----------------------------------------------------------------------------
    $ Color Palette : Only used in Style Guide
-----------------------------------------------------------------------------*/
.color-palette {
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin: 0.625rem;
}
.color-palette:first-child {
  margin-left: 0;
}
.color-palette:last-child {
  margin-right: 0;
}
.color-palette__fill {
  border-radius: 5px;
  width: 7.1875rem;
  height: 7.1875rem;
}
.color-palette__fill--primary {
  background-color: var(--color-primary-blue);
}
.color-palette__fill--black {
  background-color: var(--color-black);
}
.color-palette__fill--white {
  background-color: var(--color-heading-text);
}
.color-palette__fill--grey {
  background-color: var(--color-body-text);
}
.color-palette__fill--navy-blue {
  background-color: var(--color-navy-blue);
}
.color-palette__fill--bg-blue {
  background-color: var(--color-bg-blue);
}
.color-palette__hexcode {
  margin-top: 0.9375rem;
}
.color-palette__hexcode-value {
  color: var(--color-black);
  font-weight: 500;
}
.color-palette__name {
  margin-top: 0.9375rem;
}

/*-----------------------------------------------------------------------------
    $ Custom List
-----------------------------------------------------------------------------*/
@counter-style single-emoji {
  system: cyclic;
  symbols: "📌";
  suffix: " ";
}
.custom-list {
  list-style-type: single-emoji;
  padding-left: 1.25rem;
  font-size: 0.875rem;
  display: grid;
  line-height: 1.25rem;
  row-gap: 0.625rem;
}
.custom-list--2-cols {
  max-width: 33.75rem;
  grid-template-columns: 0.35fr 0.35fr;
  row-gap: 0.625rem;
}

/*-----------------------------------------------------------------------------
    $ Button
-----------------------------------------------------------------------------*/
button, .button {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.3125rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button {
  padding: 0.625rem 1.5625rem;
}
.button--primary {
  background-color: var(--color-primary-blue);
  color: var(--color-heading-text);
}
.button--primary:hover {
  background-color: var(--color-navy-blue);
  border-color: var(--color-primary-blue);
  color: var(--color-primary-blue);
}
.button--secondary {
  background: none;
  color: var(--color-primary-blue);
  border-color: var(--color-primary-blue);
}
.button--secondary:hover {
  background-color: var(--color-primary-blue);
  color: var(--color-heading-text);
}
@media (max-width: 576px) {
  .button {
    font-size: 0.875rem;
  }
}
.button--icon {
  position: relative;
  padding: 0.9375rem 1.5625rem;
}
.button--icon i {
  position: absolute;
  top: 50%;
  left: 1.5625rem;
  transform: translateY(-50%);
}

/*-----------------------------------------------------------------------------
    $ Card
-----------------------------------------------------------------------------*/
.cards {
  margin-left: -0.625rem;
}
@media (max-width: 768px) {
  .cards {
    margin-left: unset;
  }
}

.card {
  border-radius: 10px;
  background-color: var(--color-bg-card);
  margin: 0 0.625rem;
  text-align: center;
  flex: 1;
}
.card__inner {
  padding: 2.8125rem 1.5625rem 2.5rem;
}
.card__img {
  margin: 0 auto;
  width: 3.75rem;
}

.slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-dots {
  bottom: -45px;
}
.slick-dots li button:before {
  color: var(--color-grey) !important;
}
.slick-dots li.slick-active button:before {
  color: var(--color-primary-blue) !important;
}

.slick-prev.slick-arrow {
  left: -0.9375rem;
  z-index: 1;
}

.slick-next.slick-arrow {
  right: -0.9375rem;
  z-index: 1;
}

/*-----------------------------------------------------------------------------
    $ Footer
-----------------------------------------------------------------------------*/
.footer {
  width: 100%;
  color: var(--color-body-text);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.footer__wrapper {
  max-width: 75rem;
  padding: 1.875rem 0;
  display: flex;
  margin: 0 auto;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1200px) {
  .footer__wrapper {
    padding: 1.875rem 1.5625rem;
  }
}
@media (max-width: 768px) {
  .footer__wrapper {
    flex-direction: column;
    gap: 1.25rem;
  }
}
.footer__copyright {
  font-size: 0.75rem;
}
.footer__socials {
  display: flex;
  gap: 1.25rem;
}
.footer__social:hover i {
  color: var(--color-primary-blue);
  transform: translateY(-5px);
}
.footer__social i {
  color: var(--color-grey);
  font-size: 1.5rem;
  transition: transform 0.3s ease;
}

/*-----------------------------------------------------------------------------
    $ Loader
-----------------------------------------------------------------------------*/
/* Paste this css to your style sheet file or under head tag */
/* This only works with JavaScript, 
if it's not present, don't show loader */
.no-js #loader {
  display: none;
}

.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: var(--color-bg-dark-blue);
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.loader--load-done svg {
  animation: shrink 1s cubic-bezier(0.595, -0.13, 0.735, 0.045) forwards;
  animation-delay: 2.5s;
}
.loader-rect path {
  animation: stroke_fill 1.5s cubic-bezier(0.55, 0.055, 0.675, 0.19) forwards;
  stroke-dasharray: 324.774;
  stroke-dashoffset: 324.774;
}
.loader-d path {
  animation: stroke_fill 3s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards;
  stroke-dasharray: 324.774;
  stroke-dashoffset: 324.774;
  animation-delay: -0.2s;
}
.loader-t path {
  animation: stroke_fill 3s cubic-bezier(0.6, -0.28, 0.735, 0.045) forwards;
  stroke-dasharray: 324.774;
  stroke-dashoffset: 324.774;
  animation-delay: 0.05s;
}

@keyframes stroke_fill {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes shrink {
  to {
    transform: scale(0);
  }
}
/*-----------------------------------------------------------------------------
    $ Nav
-----------------------------------------------------------------------------*/
.nav {
  width: 100%;
  position: fixed;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 999;
}
.nav--fixed {
  position: fixed;
  background-color: transparent;
  backdrop-filter: blur(0.625rem);
  box-shadow: 0 10px 30px -10px #001658;
}
@media (max-width: 768px) {
  .nav--fixed {
    min-height: 0;
    transition: min-height 0.5s ease-in-out;
  }
}
.nav__wrapper {
  max-width: 75rem;
  padding: 1.875rem 0;
  display: flex;
  margin: 0 auto;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  transition: padding 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}
@media (max-width: 1200px) {
  .nav__wrapper {
    padding: 1.875rem 1.5625rem;
  }
}
.nav.nav--shrink .nav__wrapper {
  padding: 0.9375rem 0;
}
@media (max-width: 1200px) {
  .nav.nav--shrink .nav__wrapper {
    padding: 0.9375rem 1.5625rem;
  }
}
.nav__logo-img {
  width: 2.25rem;
  height: 2.25rem;
  display: block;
}
@media (max-width: 768px) {
  .nav__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    visibility: hidden;
    opacity: 0;
    transition: backdrop-filter 0.5s ease-in-out, visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }
  .nav__menu.\--show {
    backdrop-filter: blur(0.09375rem);
    visibility: visible;
    opacity: 1;
  }
}
.nav__menu-wrapper {
  display: flex;
}
@media (max-width: 768px) {
  .nav__menu-wrapper {
    flex-direction: column;
    position: fixed;
    top: 0;
    right: -50%;
    height: 100vh;
    width: 50%;
    padding: 6rem 0;
    background-color: var(--color-navy-blue);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
    transition: width 0.5s cubic-bezier(0.77, 0.2, 0.05, 1) 0.25s, right 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
  .nav__menu.\--show .nav__menu-wrapper {
    right: 0;
  }
}
@media (max-width: 640px) {
  .nav__menu-wrapper {
    width: 80%;
    right: -80%;
    transition: width 0.5s cubic-bezier(0.77, 0.2, 0.05, 1) 0.25s, right 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
}
.nav__hamburger {
  display: none;
  position: relative;
  cursor: pointer;
  width: 2.1875rem;
  height: 1.5625rem;
  z-index: 11;
}
@media (max-width: 768px) {
  .nav__hamburger {
    display: block;
  }
}
.nav__hamburger span {
  display: block;
  position: absolute;
  background: var(--color-body-text);
  width: 2.1875rem;
  height: 0.25rem;
  border-radius: 20px;
  transition: 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  cursor: default;
  pointer-events: none;
}
.nav__hamburger span:nth-child(1) {
  top: 0;
}
.nav__hamburger span:nth-child(2), .nav__hamburger span:nth-child(3) {
  top: 0.625rem;
}
.nav__hamburger span:nth-child(4) {
  top: 1.25rem;
}
.nav__hamburger.\--active span:nth-child(1) {
  top: 1.125rem;
  width: 0%;
  left: 50%;
}
.nav__hamburger.\--active span:nth-child(2) {
  transform: rotate(45deg);
}
.nav__hamburger.\--active span:nth-child(3) {
  transform: rotate(-45deg);
}
.nav__hamburger.\--active span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
.nav__item {
  display: flex;
}
@media (max-width: 768px) {
  .nav__item {
    margin: 15px;
  }
}
.nav__link {
  color: var(--color-nav-link);
  font-size: 0.875rem;
  letter-spacing: 0.0625rem;
  font-weight: 300;
  position: relative;
  padding: 0.625rem 1.5625rem;
}
@media (max-width: 768px) {
  .nav__link {
    font-size: 1rem;
  }
}
.nav__link::after {
  content: "";
  display: block;
  position: absolute;
  width: calc(100% - 3.125rem);
  height: 0.125rem;
  left: 0;
  right: 0;
  bottom: 0.1875rem;
  background: var(--color-primary-blue);
  transform: scaleX(0);
  transition: transform 0.3s ease;
  margin: 0 auto;
}
.nav__link:hover::after {
  transform: scaleX(1);
}

@media (max-width: 768px) {
  body.mobile-menu-active .nav--fixed {
    min-height: 100%;
  }
}

/*-----------------------------------------------------------------------------
    $ Popup
-----------------------------------------------------------------------------*/
.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(0.625rem);
  top: 0;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: all ease-out 0.4s;
}
.popup.\--active {
  visibility: visible;
  opacity: 1;
}
.popup.\--active .popup__content-left {
  bottom: 0;
}
.popup.\--active .popup__content-right {
  top: 0;
}
.popup__inner {
  width: 100%;
  height: 100%;
}
.popup__close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  width: 2rem;
  height: 2rem;
  opacity: 0.3;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 12;
}
.popup__close:hover {
  opacity: 1;
  transform: rotate(180deg);
}
.popup__close:before, .popup__close:after {
  position: absolute;
  left: 0.9375rem;
  content: " ";
  height: 2.0625rem;
  width: 0.125rem;
  background-color: var(--color-grey);
}
.popup__close:before {
  transform: rotate(45deg);
}
.popup__close:after {
  transform: rotate(-45deg);
}
.popup__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 62rem;
  height: fit-content;
  border-radius: 5px;
  display: flex;
  overflow: hidden;
}
@media (max-width: 768px) {
  .popup__content {
    height: 100%;
  }
}
@media (max-width: 640px) {
  .popup__content {
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 576px) {
  .popup__content {
    overflow-y: scroll;
    margin: 0rem auto;
  }
}
@media screen and (max-height: 768px) {
  .popup__content {
    justify-content: normal;
    overflow-y: scroll;
  }
}
.popup__content-left-right {
  width: 100%;
  display: flex;
  margin: 1.5625rem;
}
@media (max-width: 768px) {
  .popup__content-left-right {
    flex-direction: column-reverse;
    justify-content: center;
  }
}
@media screen and (max-height: 768px) {
  .popup__content-left-right {
    justify-content: start;
  }
}
.popup__content-left, .popup__content-right {
  padding: 100px 40px;
  position: relative;
  transition: all cubic-bezier(0.694, 0.048, 0.335, 1) 0.75s;
}
@media (max-width: 768px) {
  .popup__content-left, .popup__content-right {
    padding: 40px;
  }
}
@media (max-width: 576px) {
  .popup__content-left, .popup__content-right {
    padding: 60px 40px;
  }
}
.popup__content-left {
  width: 40%;
  background-color: var(--color-navy-blue);
  bottom: 1000px;
}
@media (max-width: 768px) {
  .popup__content-left {
    width: 100%;
    height: 100%;
  }
}
.popup__content-left-inner {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.popup__content-right {
  width: 60%;
  background-color: var(--color-bg-card);
  top: 1000px;
}
@media (max-width: 768px) {
  .popup__content-right {
    width: 100%;
    height: 100%;
  }
}
.popup__content-right-inner {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.popup__content-heading {
  color: var(--color-white);
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 1.5px;
  margin-bottom: 15px;
}
.popup__content-description {
  color: var(--color-grey);
  font-size: 20px;
  line-height: 1.25;
  letter-spacing: 1px;
  font-weight: 600;
}
.popup__content-description p {
  margin-top: 0;
  margin-bottom: 10px;
}
.popup__content-form-heading {
  font-size: 20px;
  line-height: 1.25;
  letter-spacing: 1px;
  font-family: "Helvetica Neue", Arial, sans-serif;
  margin-bottom: 25px;
}
.popup__content-form-heading p {
  margin: 0;
}
.popup__content-form-field-inline {
  display: flex;
  margin-bottom: 10px;
}
.popup__content-form-field-inline.\--flex-end {
  justify-content: flex-end;
}
@media (max-width: 992px) {
  .popup__content-form-field-inline.\--stacked-lg {
    flex-direction: column;
  }
  .popup__content-form-field-inline.\--stacked-lg .popup__content-form-input:first-child {
    margin-bottom: 10px;
  }
}
.popup__content-form-input, .popup__content-form-textarea {
  width: 100%;
  padding: 15px;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  background: var(--color-white);
  color: var(--color-grey);
  letter-spacing: 1.5px;
  font-weight: 600;
  appearance: none;
  border: 0;
  border-radius: 5px;
}
@media (min-width: 991px) {
  .popup__content-form-input ~ .popup__content-form-input {
    margin-left: 10px;
  }
}
.popup__content-form-textarea {
  resize: none;
}
.popup__list {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.popup__list-item {
  display: flex;
  align-items: center;
  color: var(--color-white);
}
.popup__list-item span {
  background: var(--color-white);
  border-radius: 45px;
  width: 45px;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-right: 0.9375rem;
  color: var(--color-navy-blue);
}
body.\--menu-active, body.\--popup-active {
  overflow-y: hidden;
}

/*-----------------------------------------------------------------------------
    $ Section
-----------------------------------------------------------------------------*/
.section {
  min-height: 100vh;
  padding: 6.25rem 0;
}
.section--mh-unset {
  min-height: unset;
}
.section--linktree {
  max-width: 30rem;
  margin: 0 auto;
}
@media (max-width: 1200px) {
  .section--linktree {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
}
@media (max-width: 768px) {
  .section--linktree {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
}
.section__heading {
  font-family: var(--font-horatio);
  font-size: 2rem;
  font-weight: 500;
  color: var(--color-heading-text);
  margin: 0;
}
@media (max-width: 768px) {
  .section__heading {
    font-size: 1.75rem;
  }
}
@media (max-width: 640px) {
  .section__heading {
    font-size: 1.5rem;
  }
}
.section__heading-label {
  font-family: var(--font-roboto);
  font-size: 1rem;
  color: var(--color-subheading-text);
  font-weight: 500;
}

/*-----------------------------------------------------------------------------
    $ Tabs
-----------------------------------------------------------------------------*/
.tabs {
  position: relative;
}
.tabs__line {
  position: absolute;
  height: 2px;
  background: var(--color-tab-line);
  width: 20%;
  left: 0;
  border-radius: 25px;
  transition: 0.25s ease;
}
.tabs__line-bg {
  position: absolute;
  height: 2px;
  background: var(--color-tab-line-bg);
  width: 100%;
  left: 0;
  border-radius: 25px;
  transition: 0.25s ease;
}
.tabs__content {
  position: relative;
  min-height: 18.75rem;
  margin-top: 2.1875rem;
}
@media (max-width: 768px) {
  .tabs__content {
    min-height: 37.5rem;
  }
}

.tab__input {
  display: none;
}
.tab__input:checked + label {
  color: var(--color-tab-active-text);
}
@media (max-width: 768px) {
  .tab__input:checked + label {
    color: var(--color-tab-active-text-mobile);
    background: var(--color-tab-active-text-bg-mobile);
  }
}
.tab__input#tab1:checked ~ .tabs__line {
  left: 0%;
}
.tab__input#tab1:checked ~ .tabs__content #c1 {
  opacity: 1;
  z-index: 1;
}
.tab__input#tab2:checked ~ .tabs__line {
  left: 20%;
}
.tab__input#tab2:checked ~ .tabs__content #c2 {
  opacity: 1;
  z-index: 1;
}
.tab__input#tab3:checked ~ .tabs__line {
  left: 40%;
}
.tab__input#tab3:checked ~ .tabs__content #c3 {
  opacity: 1;
  z-index: 1;
}
.tab__input#tab4:checked ~ .tabs__line {
  left: 60%;
}
.tab__input#tab4:checked ~ .tabs__content #c4 {
  opacity: 1;
  z-index: 1;
}
.tab__input#tab5:checked ~ .tabs__line {
  left: 80%;
}
.tab__input#tab5:checked ~ .tabs__content #c5 {
  opacity: 1;
  z-index: 1;
}
.tab__label {
  padding: 0.9375rem 1.5625rem;
  color: var(--color-tab-default-text);
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  width: 20%;
  text-align: center;
  position: relative;
  cursor: pointer;
  transition: 0.25s all ease;
}
@media (max-width: 768px) {
  .tab__label {
    width: 100%;
    background: var(--color-tab-content-bg);
    border-bottom: 1px solid var(--color-tab-border-mobile);
  }
  .tab__label:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .tab__label:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
.tab__label:hover {
  color: var(--color-white);
}
.tab__label::after {
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  display: block;
  background: var(--color-white);
  bottom: 0;
  opacity: 0;
  left: 0;
  transition: 0.25s ease;
}
.tab__content {
  position: absolute;
  color: var(--color-body-text);
  background: var(--color-tab-content-bg);
  border-radius: 10px;
  padding: 2.1875rem 1.875rem 2.8125rem;
  width: 100%;
  opacity: 0;
  transition: 0.25s ease;
}

/*-----------------------------------------------------------------------------
    $ Toggle
-----------------------------------------------------------------------------*/