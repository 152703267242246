/*-----------------------------------------------------------------------------
    $ Footer
-----------------------------------------------------------------------------*/

@use "../util/" as *;

.footer {
    width: 100%;
    color: var(--color-body-text);
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    
    &__wrapper {
        max-width: rem(1200);
        padding: rem(30) 0;
        display: flex;
        margin: 0 auto;
        align-content: center;
        justify-content: space-between;
        align-items: center;

        @include breakpoint-dn(xl) {
            padding: rem(30) rem(25);
        }

        @include breakpoint-dn(md) {
            flex-direction: column;
            gap: rem(20);
        }
    }
    &__copyright {
        font-size: rem(12);    
    }

    &__socials {
        display: flex;
        gap: rem(20);
    }

    &__social {
        

        &:hover {
            
            & i {
                color: var(--color-primary-blue);
                transform: translateY(-5px);
                
            }
        }

        & i {
            color: var(--color-grey);
            font-size: rem(24);
            transition: transform 0.3s ease;
        }
    }
}