/*-----------------------------------------------------------------------------
    $ Popup
-----------------------------------------------------------------------------*/

@use "../util/" as *;
@use "../util/breakpoints" as bp;
@use "sass:string";

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(0.625rem);
    // background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    z-index: 9999;
    opacity: 0;
    visibility: hidden;
    transition: all ease-out 0.4s;

    &.\--active {
        visibility: visible;
        opacity: 1;

        & .popup__content-left {
            bottom: 0;
        }

        & .popup__content-right {
            top: 0;
        }
    }

    &__inner {
        width: 100%;
        height: 100%;
    }

    &__close {
        position: absolute;
        top: rem(32);
        right: rem(32);
        width: rem(32);
        height: rem(32);
        opacity: 0.3;
        cursor: pointer;
        transition: all 0.3s ease;
        z-index: 12;
    
        &:hover {
            opacity: 1;
            transform: rotate(180deg);
        }
        &:before,
        &:after {
            position: absolute;
            left: rem(15);
            content: " ";
            height: rem(33);
            width: rem(2);
            background-color: var(--color-grey);
        }
        &:before {
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }

    &__content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        max-width: rem(992); 
        // height: 75%;
        height: fit-content;
        // box-shadow: 0 20px 80px 0 rgba(0, 0, 0, 0.55);
        border-radius: 5px;
        display: flex;
        overflow: hidden;

        @include breakpoint-dn(md) {
            height: 100%;
        }

        @include breakpoint-dn(sm) {
            flex-direction: column;
            align-content: center;
            justify-content: center;
            align-items: center;
        }

        @include breakpoint-dn(xs) {
            overflow-y: scroll;
            margin: rem(0) auto;
        }

        @media screen and (max-height: 768px) {
            justify-content: normal;
            overflow-y: scroll;
        }

        &-left-right {
            width: 100%;
            display: flex;
            margin: rem(25);

            @include breakpoint-dn(md) {
                flex-direction: column-reverse;
                justify-content: center;
            }

            @media screen and (max-height: 768px) {
                justify-content: start;
            }
        }

        &-left,
        &-right {
            padding: 100px 40px;
            position: relative;
            transition: all cubic-bezier(0.694, 0.048, 0.335, 1) 0.75s;

            @include breakpoint-dn(md) {
                padding: 40px;
            }

            @include breakpoint-dn(xs) {
                padding: 60px 40px;
            }
        }

        &-left {
            width: 40%;
            background-color: var(--color-navy-blue);
            bottom: 1000px;
            @include breakpoint-dn(md) {
                width: 100%;
                height: 100%;
            }

            &-inner {
                // margin: 45px 25px;
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: center;
            }
        }

        &-right {
            width: 60%;
            background-color: var(--color-bg-card);
            top: 1000px;

            @include breakpoint-dn(md) {
                width: 100%;
                height: 100%;
            }

            &-inner {
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: center;
            }
        }

        &-heading {
            color: var(--color-white);
            font-size: 40px;
            font-weight: 600;
            letter-spacing: 1.5px;
            // font-family: $primary-font;
            margin-bottom: 15px;
        }

        &-description {
            color: var(--color-grey);
            font-size: 20px;
            line-height: 1.25;
            letter-spacing: 1px;
            font-weight: 600;
            // font-family: $secondary-font;
        
            & p {
                margin-top: 0;
                margin-bottom: 10px;
            }
        }


        &-form {
            // font-family: "Helvetica Neue", Arial, sans-serif;

            &-heading {
                font-size: 20px;
                line-height: 1.25;
                letter-spacing: 1px;
                font-family: "Helvetica Neue", Arial, sans-serif;
                margin-bottom: 25px;
            
                & p {
                    margin: 0;
                }
            }

            &-fields {}

            &-field-inline {
                display: flex;
                margin-bottom: 10px;
            
                &.\--flex-end {
                    justify-content: flex-end;
                }

                &.\--stacked-lg {
                    @include breakpoint-dn(lg) {
                        flex-direction: column;

                        & .popup__content-form-input:first-child {
                            margin-bottom: 10px;
                        }
                    }
                }
                
            }

            &-input,
            &-textarea {
                width: 100%;
                padding: 15px;
                font-family: "Helvetica Neue", Arial, sans-serif;
                font-weight: 400;
                font-size: 14px;
                background: var(--color-white);
                color: var(--color-grey);
                letter-spacing: 1.5px;
                font-weight: 600;
                appearance: none;
                border: 0;
                border-radius: 5px;
            }

            &-input {
                & ~ .popup__content-form-input {
                    @include breakpoint(lg) {
                        margin-left: 10px;
                    }
                }
            }

            &-textarea {
                resize: none;
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 15px;

        &-item {
            display: flex;
            align-items: center;
            color: var(--color-white);
            
            span {
                background: var(--color-white);
                border-radius: 45px;
                width: 45px;
                height: 45px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                align-content: center;
                margin-right: rem(15);
                color: var(--color-navy-blue);

                i {
                
                }
            }

            a {
                
            }
        }
    }
}

body {
    &.\--menu-active,
    &.\--popup-active {
        overflow-y: hidden;
    }
}
