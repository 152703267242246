/*-----------------------------------------------------------------------------
    $ Nav
-----------------------------------------------------------------------------*/

@use "../util/" as *;
// Box Shadow reference: https://www.codegrepper.com/code-examples/css/good+box-shadow+for+dark+sidebar+navigation
// Hamburger & sidebar : https://codepen.io/erikterwan/pen/EVzeRP
.nav {
    width: 100%;
    position: fixed;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    z-index: 999;

    &--fixed {
        position: fixed;
        background-color: transparent;
        backdrop-filter: blur(rem(10));
        box-shadow: 0 10px 30px -10px rgb(0, 22, 88);
        @include breakpoint-dn(md) {
            min-height: 0;
            transition: min-height .5s ease-in-out;
        }
    }
    
    &__wrapper {
        max-width: rem(1200);
        padding: rem(30) 0;
        display: flex;
        margin: 0 auto;
        align-content: center;
        justify-content: space-between;
        align-items: center;
        transition: padding .25s cubic-bezier(0.645, 0.045, 0.355, 1);

        @include breakpoint-dn(xl) {
            padding: rem(30) rem(25);
        }

        .nav.nav--shrink & {
            padding: rem(15) 0;

            @include breakpoint-dn(xl) {
                padding: rem(15) rem(25);
            }
        }
    }

    &__logo {
        &-link {
        }

        &-img {
            width: rem(36);
            height: rem(36);
            display: block;
        }
    }

    &__menu {
        @include breakpoint-dn(md) {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
            visibility: hidden;
            opacity: 0;
            transition: backdrop-filter 0.5s ease-in-out, visibility 0.5s ease-in-out, opacity 0.5s ease-in-out;

            &.\--show {
                backdrop-filter: blur(rem(1.5));
                visibility: visible;
                opacity: 1;
            }
        }

        &-wrapper {
            display: flex;

            @include breakpoint-dn(md) {
                flex-direction: column;
                position: fixed;
                top: 0;
                right: -50%;
                height: 100vh;
                width: 50%;
                padding: rem(96) 0;
                background-color: var(--color-navy-blue);
                box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07),
                    0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
                transition: width 0.5s cubic-bezier(0.77, 0.2, 0.05, 1) 0.25s,
                    right 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

                .nav__menu.\--show & {
                    right: 0;
                }
            }

            @include breakpoint-dn(sm) {
                width: 80%;
                right: -80%;
                transition: width 0.5s cubic-bezier(0.77, 0.2, 0.05, 1) 0.25s,
                    right 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
            }
        }
    }

    &__hamburger {
        display: none;
        position: relative;
        cursor: pointer;
        width: rem(35);
        height: rem(25);
        z-index: 11;

        @include breakpoint-dn(md) {
            display: block;
        }

        & span {
            display: block;
            position: absolute;
            background: var(--color-body-text);
            width: rem(35);
            height: rem(4);
            border-radius: 20px;
            // transition: 0.25s ease-in-out;
            transition: 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
            cursor: default;
            pointer-events: none;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2),
            &:nth-child(3) {
                top: rem(10);
            }

            &:nth-child(4) {
                top: rem(20);
            }
        }

        &.\--active {
            & span {
                &:nth-child(1) {
                    top: rem(18);
                    width: 0%;
                    left: 50%;
                }

                &:nth-child(2) {
                    transform: rotate(45deg);
                }

                &:nth-child(3) {
                    transform: rotate(-45deg);
                }

                &:nth-child(4) {
                    top: 18px;
                    width: 0%;
                    left: 50%;
                }
            }
        }
    }

    &__item {
        display: flex;
        @include breakpoint-dn(md) {
            margin: 15px;
        }
    }

    &__link {
        color: var(--color-nav-link);
        font-size: rem(14);
        letter-spacing: rem(1);
        @include font-weight(300);
        position: relative;
        padding: rem(10) rem(25);

        @include breakpoint-dn(md) {
            font-size: rem(16);
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            width: calc(100% - 3.125rem);
            height: rem(2);
            left: 0;
            right: 0;
            bottom: rem(3);
            background: var(--color-primary-blue);
            transform: scaleX(0);
            transition: transform 0.3s ease;
            margin: 0 auto;
        }

        &:hover {
            &::after {
                transform: scaleX(1);
            }
        }
    }
}


body.mobile-menu-active .nav--fixed {
    @include breakpoint-dn(md) {
        min-height: 100%;
    }
}