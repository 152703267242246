/*-----------------------------------------------------------------------------
    $ Loader
-----------------------------------------------------------------------------*/

@use "../util/" as *;

/* Paste this css to your style sheet file or under head tag */
/* This only works with JavaScript, 
if it's not present, don't show loader */
.no-js #loader {
    display: none;
}
.js #loader {
    display: block;
    position: absolute;
    left: 100px;
    top: 0;
}

$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeInBack2: cubic-bezier(0.595, -0.130, 0.735, 0.045);

.loader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: var(--color-bg-dark-blue);
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;

    &--load-done {
        & svg {
            animation: shrink 1s $easeInBack2 forwards;
            animation-delay: 2.5s;
        }
    }

    &-rect {
        & path {
            animation: stroke_fill 1.5s $easeInCubic forwards;
            stroke-dasharray: 324.774;
            stroke-dashoffset: 324.774;
        }
    }
    &-d {
        & path {
            animation: stroke_fill 3s $easeInBack forwards;
            stroke-dasharray: 324.774;
            stroke-dashoffset: 324.774;
            animation-delay: -.2s;
        }
    }

    &-t {
        & path {
            animation: stroke_fill 3s $easeInBack forwards;
            stroke-dasharray: 324.774;
            stroke-dashoffset: 324.774;
            animation-delay: .05s;
        }
    }
}

@keyframes stroke_fill {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes shrink {
    to {
        transform: scale(0);
    }
}
