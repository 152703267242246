/*-----------------------------------------------------------------------------
    $ Fonts : Utils
-----------------------------------------------------------------------------*/

@use "../util/functions" as *;

$weights: (
    thin: 100,
    extra-light: 200,
    ultra-light: 200,
    light: 300,
    normal: 400,
    book: 400,
    regular: 400,
    medium: 500,
    semi-bold: 600,
    demi-bold: 600,
    bold: 700,
    extra-bold: 800,
    ultra-bold: 900,
    heavy: 900,
    black: 900,
    ultra: 900,
    ultra-black: 900,
    extra-ultra: 900,
);

$sizes: (
    xl: rem(48),
    lg: rem(36),
    md: rem(24),
    sm: rem(18),
    xs: rem(16),
    xxs: rem(14)
);

$aligns: (left, center, right, justify);


@mixin font-weight($weight) {
    $output: $weight;
    @if map-has-key($weights, $weight) {
        $output: map-get($weights, $weight);
    }

    font-weight: $output;
}

@mixin font-sizes($size) {
    $output: $size;
    @if map-has-key($sizes, $size) {
        $output: map-get($sizes, $size);
    }

    font-size: $output;
}

$weight-keys: map-keys($weights);
@each $weight-key in $weight-keys {
    $weight-value: map-get($weights, $weight-key );

    .fw-#{$weight-value} {
        font-weight:#{$weight-value};
    } 
}

$sizes-keys: map-keys($sizes);
@each $sizes-key in $sizes-keys {
    $sizes-value: map-get($sizes, $sizes-key );

    .fs-#{$sizes-key} {
        font-size:#{$sizes-value};
    } 
}

@each $align in $aligns {

    .text-#{$align} {
        text-align:#{$align};
    } 
}
