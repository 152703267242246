/*-----------------------------------------------------------------------------
    $ Colors
-----------------------------------------------------------------------------*/
:root {
    --color-black: hsl(0, 0%, 0%);
    --color-bg-blue: hsla(220, 100%, 13%, 1);
    --color-bg-dark-blue: hsla(220, 100%, 8%, 1);
    --color-navy-blue: hsla(220, 100%, 21%, 1);
    --color-primary-blue: hsla(204, 100%, 50%, 1);
    --color-white: hsla(201, 100%, 97%, 1);
    --color-grey: hsla(217, 34%, 60%, 1);
    --color-bg-card: hsla(220, 86%, 19%, 1);
    --color-bg-tag: hsla(204, 100%, 23%, 1);

    --color-subheading-text: var(--color-primary-blue);
    --color-heading-text: var(--color-white);
    --color-heading-text-2: var(--color-grey);
    --color-body-text: var(--color-grey);

    --color-nav-link: var(--color-white);
    --color-bg-main: var(--color-bg-blue);

    --color-mobile-nav-menu: hsla(0, 0%, 0%, 0.5);

    --color-tab-active-text: var(--color-primary-blue);
    --color-tab-active-text-mobile: var(--color-white);
    --color-tab-active-text-bg-mobile: var(--color-primary-blue);
    --color-tab-border-mobile: hsl(214, 90%, 25%);
    --color-tab-default-text: var(--color-grey);
    --color-tab-line: var(--color-primary-blue);
    --color-tab-line-bg: hsla(220, 14%, 45%, 1);
    --color-tab-content-bg: var(--color-bg-card);
}
