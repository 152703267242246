/*-----------------------------------------------------------------------------
    $ Display

    References:
    https://github.com/malothnaresh/css-utilities/blob/master/common.scss
    https://medium.com/@jerrythimothy/margins-paddings-sass-mixin-16460c32507f
-----------------------------------------------------------------------------*/

@use "../util/functions" as *;
@use "../util/breakpoints" as bp;
@use "sass:math";

// Utilities : Display Flex
$display: flex;
$flex: (
    flex-direction: (
        row,
        row-reverse,
        column,
        column-reverse,
    ),
    flex-wrap: (
        nowrap,
        wrap,
        wrap-reverse,
    ),
    justify-content: (
        flex-start,
        flex-end,
        center,
        space-between,
        space-around,
        space-evenly,
        start,
        end,
    ),
    align-items: (
        stretch,
        flex-start,
        flex-end,
        center,
        baseline,
        start,
        end,
    ),
    align-content: (
        flex-start,
        flex-end,
        center,
        space-between,
        space-around,
        space-evenly,
        stretch,
        start,
        end,
        baseline,
    ),
);

$gap: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50);
// $all: ($flex-direction, $flex-wrap, $justify-content, $align-items, $align-content);
// $breakpoints: (xs, sm, md, lg, xl);

$flex-keys: map-keys($flex);
@each $flex-key in $flex-keys {
    $flex-values: map-get($flex, $flex-key);
    @each $flex-value in $flex-values {
        .#{$flex-key}-#{$flex-value} {
            #{$flex-key}: #{$flex-value};
        }
    }
}

$display-breakpoints-keys: map-keys(bp.$breakpoints-down);
@each $breakpoint in $display-breakpoints-keys {
    @include bp.breakpoint-dn(#{$breakpoint}) {
        @each $flex-key in $flex-keys {
            $flex-values: map-get($flex, $flex-key);
            @each $flex-value in $flex-values {
                .#{$flex-key}-#{$flex-value}-#{$breakpoint} {
                    #{$flex-key}: #{$flex-value};
                }
            }
        }
    }
}

// Utilities : Display None
$display-types: (block, inline-block, flex, grid, none);
@each $display-type in $display-types {
    .d-#{$display-type} {
        display: $display-type;
    }
}

@each $breakpoint in $display-breakpoints-keys {
    @include bp.breakpoint-dn(#{$breakpoint}) {
        @each $display-type in $display-types {
            .d-#{$display-type}-#{$breakpoint} {
                display: $display-type;
            }
        }
    }
}
