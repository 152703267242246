/*-----------------------------------------------------------------------------
    $ Color Palette : Only used in Style Guide
-----------------------------------------------------------------------------*/
@use '../util/' as *;

.color-palette {
    color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: rem(10);

    &:first-child{
        margin-left: 0;
    }

    &:last-child{
        margin-right: 0;
    }

    &__fill {
        border-radius: 5px;
        width: rem(115);
        height: rem(115);

        &--primary {
            background-color: var(--color-primary-blue);
        }
        &--black {
            background-color: var(--color-black);
        }
        &--white {
            background-color: var(--color-heading-text);
        }
        &--grey {
            background-color: var(--color-body-text);
        }
        &--navy-blue {
            background-color: var(--color-navy-blue);
        }
        &--bg-blue {
            background-color: var(--color-bg-blue);
        }
    }

    &__hexcode {
        margin-top: rem(15);

        &-value {
            color: var(--color-black);
            @include font-weight(500);
        }
    }

    &__name {
        margin-top: rem(15);
    }
}