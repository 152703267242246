/*-----------------------------------------------------------------------------
    $ Custom List
-----------------------------------------------------------------------------*/
@use "../util/" as *;

@counter-style single-emoji {
    system: cyclic;
    symbols: "\1F4CC"; // unicode code point
    suffix: " ";
}

.custom-list {
    list-style-type: single-emoji;
    padding-left: rem(20);
    font-size: rem(14);
    display: grid;
    line-height: rem(20);
    row-gap: rem(10);

    &--2-cols {
        max-width: rem(540);
        grid-template-columns: .35fr .35fr;
        row-gap: rem(10);
    }
}