/*-----------------------------------------------------------------------------
    $ Button
-----------------------------------------------------------------------------*/
@use '../util/' as *;

button, .button {
    display: inline-block;
    @include font-weight(700);
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: rem(5);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.button {
    padding: rem(10) rem(25);

    &--primary {
        background-color: var(--color-primary-blue);
        color: var(--color-heading-text);

        &:hover {
            background-color: var(--color-navy-blue);
            border-color: var(--color-primary-blue);
            color: var(--color-primary-blue);
        }
    }

    &--secondary {
        background: none;
        color: var(--color-primary-blue);
        border-color: var(--color-primary-blue);

        &:hover {
            background-color: var(--color-primary-blue);
            color: var(--color-heading-text);
        }
    }

    @include breakpoint-dn(xs) {
        font-size: rem(14);
    }

    &--icon {
        position: relative;
        padding: rem(15) rem(25);

        & i {   
            position: absolute;
            top: 50%;
            left: rem(25);
            transform: translateY(-50%);
        }
    }
}