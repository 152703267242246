/*-----------------------------------------------------------------------------
    $ Breakpoints
-----------------------------------------------------------------------------*/

$breakpoints-up: (
  'xs' : '575px',
  'sm' : '639px',
  'md' : '767px',
  'lg' : '991px',
  'xl' : '1199px',
);

$breakpoints-down: (
  'xs' : '576px',
  'sm' : '640px',
  'md' : '768px',
  'lg' : '992px',
  'xl' : '1200px',
);

@mixin breakpoint($size) {
    @media (min-width: map-get($breakpoints-up, $size)) {
        @content;
    }
}

@mixin breakpoint-dn($size) {
    @media (max-width: map-get($breakpoints-down, $size)) {
        @content;
    }
}