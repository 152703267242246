/*-----------------------------------------------------------------------------
    $ Fonts : Globals
-----------------------------------------------------------------------------*/

@forward "fonts/horatio-d";
@forward "fonts/roboto";

:root {
    --font-horatio: "Horatio D";
    --font-roboto: "Roboto";
}