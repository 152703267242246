/*-----------------------------------------------------------------------------
    $ Typography
-----------------------------------------------------------------------------*/
@use "../util" as *;

h1,
h2,
h3,
h4,
h5 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    line-height: 1.5;
}

a,
a:visited,
a:active {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
    border-style: none;
    display: block;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
    color: var(--color-body-text);
}

h1 {
    @include font-sizes(xl);

    @include breakpoint-dn(md) {
        font-size: rem(40);
    }

    @include breakpoint-dn(sm) {
        font-size: rem(32);
    }
}

h2 {
    @include font-sizes(lg);

    @include breakpoint-dn(md) {
        font-size: rem(32);
    }

    @include breakpoint-dn(sm) {
        font-size: rem(26);
    }
}

h3 {
    @include font-sizes(md);

    @include breakpoint-dn(md) {
        font-size: rem(24);
    }

    @include breakpoint-dn(sm) {
        font-size: rem(22);
    }
}

h4 {
    @include font-sizes(sm);
}

h5 {
    @include font-sizes(xs);
}

p {
    color: var(--color-body-text);
    line-height: 1.5;
    font-size: rem(16);
    margin: 0;
}

.text {
    &-black {
        color: var(--color-black);
    }
    &-white {
        color: var(--color-heading-text);
    }
    &-grey {
        color: var(--color-body-text);
    }
    &-primary-blue {
        color: var(--color-primary-blue);
    }
    &-navy-blue {
        color: var(--color-navy-blue);
    }
}

.ff {
    &-primary {
        font-family: var(--font-horatio), Helvetica, sans-serif;
    }

    &-secondary {
        font-family: var(--font-roboto), Helvetica, sans-serif;
    }
}

.display-heading {
    &-1 {
        font-size: rem(72);
        line-height: 1.25;

        @include breakpoint-dn(md) {
            font-size: rem(54);
        }

        @include breakpoint-dn(sm) {
            font-size: rem(40);
        }
    }
}

.text-subheading {
    font-size: rem(16);
    color: var(--color-subheading-text);
    margin-bottom: rem(5);
    letter-spacing: rem(1);

    @include breakpoint-dn(sm) {
        font-size: rem(14);
    }
}

.text-heading {
    
}

.text-paragraph {


    @include breakpoint-dn(sm) {
        font-size: rem(14);
    }

    &--mw-1 {
        max-width: rem(540);
    }

    &--mw-2-desktop {
        max-width: rem(480);
        
        @include breakpoint-dn(md) {
            max-width: unset;
        }
    }
}

a {
    color: var(--color-primary-blue);
    cursor: pointer;

    &.hyperlink {
        &:hover {
            text-decoration: underline;
        }
    }
}
