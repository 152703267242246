/*-----------------------------------------------------------------------------
    $ Boilerplate
-----------------------------------------------------------------------------*/
html {
    font-size: 100%;
    box-sizing: border-box;
    scroll-behavior: smooth;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    margin: 0;
    padding: 0;
    font-family: var(--font-roboto), Helvetica, sans-serif; // or custom fonts
    background-color: var(--color-bg-main);

    &.mobile-menu-active,
    &.popup-active {
        overflow-y: hidden;
    }

    &.bg-white {
        background-color: white;
    }
}
