/*-----------------------------------------------------------------------------
    $ Spacing

    References:
    https://github.com/malothnaresh/css-utilities/blob/master/common.scss
    https://medium.com/@jerrythimothy/margins-paddings-sass-mixin-16460c32507f
-----------------------------------------------------------------------------*/

@use "../util/functions" as *;
@use "../util/breakpoints" as *;
@use "sass:math";

// Utilities : Paddings, Margins
$max: 100;
$offset: 5;
// $spaces: (0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20);
// $spaces: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50);
$sides: (top, right, bottom, left);
// $breakpoints: (xs, sm, md, lg, xl);
/*-----------------------------------------------------------------------------
    Bootstrap utility classes:
    p-0: [padding: 0rem]
    ml-0: [margin-left: 0rem]

    pl-1 : [ padding-left: 0.25rem ]
    mr-2 : [ margin-right: 0.5rem ]
    pt-3 : [ padding-top: 0.75rem ]
    mb-4 : [ margin-bottom: 1rem ]

    pl-5 : [ padding-left: 1.25rem ]
    mr-6 : [ margin-right: 1.5rem ]
    pt-7 : [ padding-top: 1.75rem ]
    mb-8 : [ margin-bottom: 2rem ]
-----------------------------------------------------------------------------*/

$i: 0;
@while $i <= $max {
    .m-#{$i} {
        margin: rem($i);
    }
    .p-#{$i} {
        padding: rem($i);
    }
    @each $side in $sides {
        .m#{str-slice($side, 0, 1)}-#{$i} {
            margin-#{$side}: rem($i) !important;
        }

        .p#{str-slice($side, 0, 1)}-#{$i} {
            padding-#{$side}: rem($i) !important;
        }
    }
    $i: $i + $offset;
}

$spacing-breakpoints-keys: map-keys($breakpoints-down);
@each $breakpoint in $spacing-breakpoints-keys {
    @include breakpoint-dn(#{$breakpoint}) {
        $i: 0;
        @while $i <= $max {
            .m-#{$i}-#{$breakpoint} {
                margin: rem($i);
            }
            .p-#{$i}-#{$breakpoint} {
                padding: rem($i);
            }
            @each $side in $sides {
                .m#{str-slice($side, 0, 1)}-#{$i}-#{$breakpoint} {
                    margin-#{$side}: rem($i) !important;
                }

                .p#{str-slice($side, 0, 1)}-#{$i}-#{$breakpoint} {
                    padding-#{$side}: rem($i) !important;
                }
            }
            $i: $i + $offset;
        }
    }
}
