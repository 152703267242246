/*-----------------------------------------------------------------------------
    $ Width

    References:
    https://github.com/malothnaresh/css-utilities/blob/master/common.scss
    https://medium.com/@jerrythimothy/margins-paddings-sass-mixin-16460c32507f
-----------------------------------------------------------------------------*/

@use "../util/functions" as *;
@use "../util/breakpoints" as bp;
@use "sass:math";

$widths: (5, 10, 15, 20, 25, 30, 33, 50, 70, 75, 80, 85, 90, 95, 100, auto);

@each $width in $widths {
    .w-#{$width} {
        @if type-of($width) == "string" {
            width: $width;
        } @else {
            width: math.percentage(math.div($width, 100));
        }
    }
}

$width-breakpoints-keys: map-keys(bp.$breakpoints-down);
@each $breakpoint in $width-breakpoints-keys {
    @include bp.breakpoint-dn(#{$breakpoint}) {
        @each $width in $widths {
            .w-#{$width}-#{$breakpoint} {
                @if type-of($width) == "string" {
                    width: $width;
                } @else {
                    width: math.percentage(math.div($width, 100));
                }
            }
        }
    }
}
