/*-----------------------------------------------------------------------------
    $ Tabs
-----------------------------------------------------------------------------*/
@use "../util/" as *;

$tab-width: 20%;

.tabs {
    position: relative;

    &__line {
        position: absolute;
        height: 2px;
        background: var(--color-tab-line);
        width: $tab-width;
        left: 0;
        border-radius: 25px;
        transition: 0.25s ease;

        &-bg {
            position: absolute;
            height: 2px;
            background: var(--color-tab-line-bg);
            width: 100%;
            left: 0;
            border-radius: 25px;
            transition: 0.25s ease;
        }
    }

    &__content {
        // background: var(--color-tab-content-bg);
        position: relative;
        min-height: rem(300);
        margin-top: rem(35);
        // border-radius: 10px;

        @include breakpoint-dn(md) {
            min-height: rem(600);
        }
    }
}

.tab {
    &__input {
        display: none;

        &:checked + label {
            color: var(--color-tab-active-text);

            @include breakpoint-dn(md) {
                color: var(--color-tab-active-text-mobile);
                background: var(--color-tab-active-text-bg-mobile);
            }
        }

        @for $i from 1 through 5 {
            &#tab#{$i}:checked {
                ~ .tabs__line {
                    left: #{($i - 1) * $tab-width};
                }
                ~ .tabs__content #c#{$i} {
                    opacity: 1;
                    z-index: 1;
                }
            }
        }
    }

    &__label {
        padding: rem(15) rem(25);
        color: var(--color-tab-default-text);
        display: inline-block;
        font-size: rem(16);
        @include font-weight(500);
        width: $tab-width;
        text-align: center;
        position: relative;
        cursor: pointer;
        transition: 0.25s all ease;

        @include breakpoint-dn(md) {
            width: 100%;
            background: var(--color-tab-content-bg);
            border-bottom: 1px solid var(--color-tab-border-mobile);
            
            &:first-of-type {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            
            &:last-of-type {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
        }

        &:hover {
            color: var(--color-white);
        }

        &::after {
            content: "";
            height: 2px;
            width: 100%;
            position: absolute;
            display: block;
            background: var(--color-white);
            bottom: 0;
            opacity: 0;
            left: 0;
            transition: 0.25s ease;
        }

        &:hover::after {
            // opacity: 1;
        }
    }

    &__content {
        position: absolute;
        color: var(--color-body-text);
        background: var(--color-tab-content-bg);
        border-radius: 10px;
        padding: rem(35) rem(30) rem(45);
        width: 100%;
        opacity: 0;
        transition: 0.25s ease;
    }
}
